import React, {useState, useEffect, useMemo, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DateTime} from 'luxon';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';

import {machineTypeMapping, unitsAreaConversion, unitsLengthDisplayConversion} from '../../app/utils';
import {setSelectedVehicle, setSelectedTask, setRefreshCabviewDataStream} from './cabviewSlice';
import {updateDisplayedPlotLines, updateDisplayClusters} from './cabviewSlice';
import {Map, useMap} from '@vis.gl/react-google-maps';
import {GeoJsonLayer, IconLayer, TextLayer, LineLayer} from '@deck.gl/layers';
import {GoogleMapsOverlay} from '@deck.gl/google-maps';
import IconClusterLayer from './icon-cluster-layer';
import {Divider} from '@mui/material';

// Icons for vehicle markers
const machineIconMapping = {
  0: '/img/tractor_green.png',
  1: '/img/passenger-vehicle.png',
  2: '/img/tractor_green.png',
  3: '/img/trailer.png',
  4: '/img/atv.png',
  5: '/img/platform.png',
  6: '/img/construction.png',
};

const imgTracGreen = new Image();
imgTracGreen.src = '/img/tractor_green.png';
const imgPassenger = new Image();
imgPassenger.src = '/img/passenger-vehicle.png';
const imgTrailer = new Image();
imgTrailer.src = '/img/trailer.png';
const imgATV = new Image();
imgATV.src = '/img/atv.png';
const imgPlatform = new Image();
imgPlatform.src = '/img/platform.png';
const imgConstruction = new Image();
imgConstruction.src = '/img/construction.png';

const machineIconImageMapping = {
  0: imgTracGreen,
  1: imgPassenger,
  2: imgTracGreen,
  3: imgTrailer,
  4: imgATV,
  5: imgPlatform,
  6: imgConstruction,
};
const machineTypeList = Object.keys(machineTypeMapping).map((str) => {
  return parseInt(str);
});

// Global variables
let autoZoomInProgress = false; // Prevents event handlers during non-user triggered map zooming
// let map = null; // Reference to google 'map' object
// let maps = null; // Reference to google 'maps' object

const zoneColorsList = ['#4daf4a', '#4a4daf', '#af4a99', '#000000', '#ffffff'];

function DeckGLOverlay(props) {
  const map = useMap();
  const overlay = useMemo(() => {
    return new GoogleMapsOverlay(props);
  }, []);

  useEffect(() => {
    overlay.setMap(map);
    return () => {
      return overlay.setMap(null);
    };
  }, [map]);

  overlay.setProps(props);

  return null;
}

function MapControlButtons(props) {
  const map = useMap();
  const ref = useRef();

  useEffect(() => {
    if (map && ref) {
      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(ref.current);
    }
  }, [map, ref]);

  return (
    <div ref={ref}>
      <div>
        <button
          className='btn-lg bg-light mt-2 mr-2'
          onClick={() => {
            return props.setMapState({locate: true, showAll: false});
          }}
        >
          <FontAwesomeIcon icon={props.mapState.locate ? 'fas fa-map-marker' : 'fas fa-map-marker-alt'} fixedWidth />
        </button>
      </div>
      <div>
        <button
          className='btn-lg bg-light mr-2'
          onClick={() => {
            return props.setMapState({locate: false, showAll: true});
          }}
        >
          <FontAwesomeIcon icon={props.mapState.showAll ? 'fas fa-eye' : 'far fa-eye'} fixedWidth />
        </button>
      </div>
      <div>
        <div>
          <button
            className='btn-lg bg-light mr-2'
            onClick={() => {
              return props.setPlotLinesModalOpen(true);
            }}
          >
            <FontAwesomeIcon icon='fa-solid fa-road' fixedWidth />
          </button>
        </div>
      </div>
      <div>
        <div>
          <button
            className='btn-lg bg-light mr-2'
            onClick={() => {
              return props.refreshSocket();
            }}
          >
            <FontAwesomeIcon icon='fa-solid fa-arrows-rotate' fixedWidth />
          </button>
        </div>
      </div>
      <div>
        <button
          className='btn-lg bg-light mr-2'
          onClick={() => {
            return props.setDisplayZones(!props.displayZones);
          }}
        >
          <FontAwesomeIcon icon={props.displayZones ? 'fas fa-clone' : 'far fa-clone'} fixedWidth />
        </button>
      </div>

      {props.displayZones && (
        <React.Fragment>
          <div>
            <button
              className='btn-lg bg-light mr-2 mt-n1'
              style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
              onClick={() => {
                return props.changeZoneLabels();
              }}
            >
              <FontAwesomeIcon icon={'fas fa-comment-alt'} fixedWidth />
            </button>
          </div>
          <div>
            <button
              className='btn-lg bg-light mr-2 mt-n1'
              style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
              onClick={() => {
                return props.changeZonesColor();
              }}
            >
              <FontAwesomeIcon icon={'fas fa-palette'} fixedWidth />
            </button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

function CabViewMap({socket}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const vehicleData = useSelector((state) => {
    return state.cabview.vehicles;
  });
  const hazardData = useSelector((state) => {
    return state.cabview.hazards;
  });
  const selectedVehicle = useSelector((state) => {
    return state.cabview.selectedVehicle;
  });
  const zonesDicts = useSelector((state) => {
    return state.cabview.zonesDicts;
  });
  const geofencesDict = useSelector((state) => {
    return state.cabview.geofencesDict;
  });
  const reiActiveDict = useSelector((state) => {
    return state.cabview.reiActiveDict;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const taskActive = useSelector((state) => {
    return state.cabview.taskActive;
  });
  const vehiclePlotLinesDisplay = useSelector((state) => {
    return state.cabview.displayedPlotLines;
  });
  const displayClusters = useSelector((state) => {
    return state.cabview.displayClusters;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const unitsAreaSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsArea;
  });

  const [zoneColorIndex, setZoneColorIndex] = useState(0);

  // Single zone info window so that only one is open at a time
  const [zoneInfoWindow, setZoneInfoWindow] = useState(null);

  // Single vehicle info window so that only one is open at a time
  const [vehInfoWindow, setVehInfoWindow] = useState(null);

  const [mapState, setMapState] = useState({
    locate: false,
    showAll: true,
  });
  const [mapZoomLevel, setMapZoomLevel] = useState(0);
  const [displayZones, setDisplayZones] = useState(true);
  const [zoneLabelsIndex, setZoneLabelsIndex] = useState(0);
  const [geofencePolygonsByIBlockNum, setGeofencePolygonsByIBlockNum] = useState({});

  const [vehicleTypesArray, setVehicleTypesArray] = useState([]);
  const [plotLinesModalOpen, setPlotLinesModalOpen] = useState(false);

  const [clusterSelectedVehicle, setClusterSelectedVehicle] = useState('');

  const [loaded, setLoaded] = useState(null);

  const [vehicleMarkers, setVehicleMarkers] = useState([]);
  const [hazardMarkers, setHazardMarkers] = useState([]);
  const [pathLineData, setPathLineData] = useState([]);
  const [pathArrowData, setPathArrowData] = useState([]);
  const [geofenceLayerData, setGeofenceLayerData] = useState(null);
  const [labelLayerData, setLabelLayerData] = useState(null);

  const map = useMap();

  // Set initial plot lines display based on customer setting
  let initPlotLineDisplay = [];
  if (customerSettings.cabview.initPlotLineDisplayOn) {
    initPlotLineDisplay = machineTypeList;
  }

  const zoneInfoEnabled =
    typeof userSettings.general.zoneInfoEnabled != 'undefined' && userSettings.general.zoneInfoEnabled;

  useEffect(() => {
    setMapState({
      locate: false,
      showAll: true,
    });
  }, []);

  // Onload function
  useEffect(() => {
    if (loaded) {
      const zoneInfoWin = new google.maps.InfoWindow();
      const vehInfoWin = new google.maps.InfoWindow();
      setZoneInfoWindow(zoneInfoWin);
      setVehInfoWindow(vehInfoWin);

      // Event listeners for clearing map states
      map.addListener('dragstart', function () {
        if (!autoZoomInProgress && (mapState.locate !== false || mapState.showAll !== false)) {
          setMapState({locate: false, showAll: false});
        }
      });
      map.addListener('zoom_changed', function () {
        if (!autoZoomInProgress && (mapState.locate !== false || mapState.showAll !== false)) {
          setMapState({locate: false, showAll: false});
        }
        setMapZoomLevel(map.getZoom());
      });

      setMapZoomLevel(map.getZoom());
    }
  }, [loaded]);

  // Generate data for path arrows
  useEffect(() => {
    const pathArrows = pathLineData.flatMap((d) => {
      return generateArrowPositions([d.start, d.end], 0.00073, 0.00001).map((pos) => {
        return {
          vehiclesn: d.vehicleSN,
          position: pos,
        };
      });
    });

    setPathArrowData(pathArrows);
  }, [pathLineData]);

  useEffect(() => {
    // Trigger a map locate or showAll action if the mapState has been changed
    if (map != null) {
      updateClusterInfoWindowButtons();
    }
  }, [taskActive]);

  useEffect(() => {
    // Updated markers on map if new data or vehicle selection changed
    if (map != null) {
      updateMap();
    }

    // Determine vehicle types
    const vehicleTypesArrayHolder = [];
    vehicleData.forEach((vehicleObj) => {
      const machineType = vehicleObj.machineType != undefined ? vehicleObj.machineType : 0;
      if (!vehicleTypesArrayHolder.includes(machineType)) {
        vehicleTypesArrayHolder.push(machineType);
      }
    });
    setVehicleTypesArray(vehicleTypesArrayHolder);
  }, [
    vehicleData,
    hazardData,
    selectedVehicle,
    taskActive,
    vehiclePlotLinesDisplay,
    displayClusters,
    zoneInfoWindow,
    vehInfoWindow,
  ]);

  useEffect(() => {
    if (map != null) {
      // Trigger a map locate or showAll action if the mapState has been changed
      if (mapState.locate) {
        locateVehicle();
      } else if (mapState.showAll) {
        showAllVehicles();
      }
    }
  }, [mapState, vehicleMarkers]);

  // Generate data for geofence and label layers
  useEffect(() => {
    if (typeof zonesDicts.blocks === 'undefined') {
      return;
    }

    // Use geojson layer becaus eof multipolygons, after new zone management is implement
    // consider using polygon layer since geofence structure changed
    const geojsonBase = {
      type: 'FeatureCollection',
      name: 'Geofences Layer',
      crs: {
        type: 'name',
        properties: {
          name: 'urn:ogc:def:crs:OGC:1.3:CRS84',
        },
      },
      features: [],
    };

    // Create list for labels
    const labelData = [];

    // Iterate through blocks
    Object.values(zonesDicts.blocks).forEach((block) => {
      const fieldName = zonesDicts.fields[block.fieldId] ? zonesDicts.fields[block.fieldId].name : '';
      const regionName = zonesDicts.regions[block.regionId] ? zonesDicts.regions[block.regionId].name : '';

      const blockIntelliBlockNums = block.intelliblockNums;

      // Determine if REI warning should show
      let reiWarningShow = false;
      let remRei = 0;
      if (Object.prototype.hasOwnProperty.call(reiActiveDict, block.id)) {
        const reiObj = reiActiveDict[block.id];
        const reiEndTime = DateTime.fromISO(reiObj.exitTime['@ts']).plus({hours: reiObj.reiHours});
        remRei = reiEndTime.diff(DateTime.now(), 'hours').toObject()?.hours;

        // Final check to ensure rei interval is valid
        if (remRei && remRei > 0 && remRei <= reiObj.reiHours) {
          reiWarningShow = true;
          remRei = Math.ceil(remRei);
        }
      }

      // Get center point of block
      const latCenter = (block.latMax + block.latMin) / 2;
      const lngCenter = (block.lngMax + block.lngMin) / 2;

      // Determine acreage
      // Convert acreage area to desired units
      let unitsArea = 'ac';
      if (unitsAreaSystem == 'hectare') {
        unitsArea = 'ha';
      }
      const convertedAcreage = unitsAreaConversion(block.areaMeters2, unitsArea, 'meters2');
      const acreageWithUnit = `${convertedAcreage.toFixed(2)} ${unitsArea}`;

      // Iterate through each multipolygon for plotting
      let labelAdded = false;
      for (let j = 0; j < blockIntelliBlockNums.length; j++) {
        if (!Object.prototype.hasOwnProperty.call(geofencesDict, blockIntelliBlockNums[j])) {
          continue;
        }

        const geofence = geofencesDict[blockIntelliBlockNums[j]];

        // Determine row spacing
        let rowSpacingWithUnit = 'N/A';
        if (geofence.rowSpacingMeters > 0) {
          rowSpacingWithUnit =
            unitsLengthSystem == 'imperial'
              ? `${unitsLengthDisplayConversion(geofence.rowSpacingMeters, 'ft').toFixed(2)} ft`
              : `${geofence.rowSpacingMeters.toFixed(2)} m`;
        }

        // Add to label list, once per block
        if (!labelAdded) {
          labelAdded = true;
          const newLabelData = {
            position: [lngCenter, latCenter],
            blockName: block.name,
            fieldName: fieldName,
            regionName: regionName,
            rowSpacingWithUnit: rowSpacingWithUnit,
            acreageWithUnit: acreageWithUnit,
            reiWarningShow: reiWarningShow,
            remRei: remRei,
          };

          if (Object.prototype.hasOwnProperty.call(block, 'notes')) {
            newLabelData.notes = `${block.notes}`;
          }

          labelData.push(newLabelData);
        }

        // Add additional data to geofences needed for displaying
        const newGeofenceObj = {...geofence};
        newGeofenceObj.reiWarningShow = reiWarningShow;
        newGeofenceObj.remRei = remRei;
        newGeofenceObj.rowSpacingWithUnit = rowSpacingWithUnit;
        newGeofenceObj.acreageWithUnit = acreageWithUnit;
        newGeofenceObj.blockName = block.name;
        newGeofenceObj.fieldName = fieldName;
        newGeofenceObj.regionName = regionName;

        if (Object.prototype.hasOwnProperty.call(block, 'notes')) {
          newGeofenceObj.notes = `${block.notes}`;
        }

        geojsonBase.features.push(newGeofenceObj);
      }
    });

    setGeofenceLayerData(geojsonBase);
    setLabelLayerData(labelData);
  }, [geofencesDict, zonesDicts, reiActiveDict]);

  useEffect(() => {
    // Locate vehicle if vehicle selection changed
    if (map != null) {
      if (selectedVehicle != '') {
        setMapState({
          locate: true,
          showAll: false,
        });
      }
    }
  }, [selectedVehicle]);

  useEffect(() => {
    // listen to cluster InfoWindow select vehicle
    if (map != null) {
      if (clusterSelectedVehicle != '') {
        selectVehicle(clusterSelectedVehicle);
      }
    }
  }, [clusterSelectedVehicle]);

  useEffect(() => {
    if (map != null) {
      plotGeofences();
    }
  }, [geofencesDict, zonesDicts, reiActiveDict]);

  useEffect(() => {
    if (vehInfoWindow) {
      vehInfoWindow.close();
    }
    if (zoneInfoWindow) {
      zoneInfoWindow.close();
    }
  }, [mapZoomLevel]);

  // Options for google map appearance and functionality
  const mapOptions = {
    disableDefaultUI: true,
    mapTypeId: 'hybrid',
    rotateControl: false,
    tilt: 0,
  };

  // Plot lines display box style and functionality
  const plotLinesDisplayBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    borderRadius: '16px', // Added rounded corners
    p: 4,
    backdropFilter: 'blur(8px)',
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Slight transparency
    border: 'none',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12), 0 2px 8px rgba(0, 0, 0, 0.04)', // Layered shadow
  };

  function vehicleMarkerOnClickHandler(markerObj) {
    if (!taskActive) {
      selectVehicle(markerObj.serialNumber);
    }
    vehInfoWindow.setContent(markerObj.infoWindowContent);
    vehInfoWindow.setPosition(new google.maps.LatLng(markerObj.coordinates[1], markerObj.coordinates[0]));
    vehInfoWindow.open(map);
  }

  function hazardMarkerOnClickHandler(markerObj) {
    const contentString =
      '<div id="content">' + '<h6 id="firstHeading" class="firstHeading">' + markerObj.hazardType + '</h6>' + '</div>';
    vehInfoWindow.setContent(contentString);
    vehInfoWindow.setPosition(markerObj.hazardCoordinate);
    vehInfoWindow.open(map);
  }

  function onClusterLayerHandler(info, map) {
    // Check if cluster is zoomed (Cluster Does not break at high zoom means markers are overlapped)
    if (info.objects) {
      // if is cluster
      if (mapZoomLevel <= 18) {
        // zoom to fit bound and attempt to decluster
        const lats = info.objects.map((marker) => {
          return marker.position[1];
        });
        const lngs = info.objects.map((marker) => {
          return marker.position[0];
        });
        const bounds = {
          north: Math.max(...lats),
          east: Math.max(...lngs),
          south: Math.min(...lats),
          west: Math.min(...lngs),
        };
        map.fitBounds(bounds);
      } else {
        // Generate cluster InfoWindow Content
        const clusterContentString = generateClusterInfoWindow(info.objects);
        // Open InfoWindow on cluster Location
        if (typeof vehInfoWindow != 'undefined' && vehInfoWindow != null) {
          vehInfoWindow.setPosition(new google.maps.LatLng(info.coordinate[1], info.coordinate[0]));
          vehInfoWindow.setContent(clusterContentString);
          vehInfoWindow.open(map);
        }

        // Add event listener to InfoWindow Buttons since functions cannot be dynamically added in string format.
        setTimeout(() => {
          updateClusterInfoWindowButtons();
        }, 200);
      }
    } else {
      // if is not cluster
      vehicleMarkerOnClickHandler(info.object);
    }
  }

  function selectVehicle(vehicleSN) {
    const vehicleObj = vehicleData.find((vehicle) => {
      return vehicle.serialNumber == vehicleSN;
    });

    if (!taskActive) {
      // Auto Select the task if the vehicle was previously doing a task
      if (Object.prototype.hasOwnProperty.call(vehicleObj, 'latestTaskId') && vehicleObj['latestTaskId'] !== null) {
        const taskId = vehicleObj['latestTaskId'];
        dispatch(setSelectedTask(taskId));
        // Emit a socket message to backend
        socket.emit('setSelectedTask', {taskId});
      }
      dispatch(setSelectedVehicle(vehicleSN));
      // Emit a socket message to backend
      socket.emit('setSelectedVehicle', {vehicleSN});
    }
  }

  // Function to generate arrow positions
  const generateArrowPositions = (path, spacing, minSpacing = 0.00073) => {
    const positions = [];
    let noArrowDistance = 0;

    for (let i = 0; i < path.length - 1; i++) {
      const start = path[i];
      const end = path[i + 1];
      const distance = Math.hypot(end[0] - start[0], end[1] - start[1]);
      const angle = 90 - Math.atan2(end[0] - start[0], end[1] - start[1]) * (180 / Math.PI);
      const numArrows = Math.floor(distance / spacing);

      if (numArrows == 0) {
        if (noArrowDistance + distance > minSpacing) {
          // Inject an arrow if the total distance without arrow in this path is over the spacing
          const x = start[0] + 0.5 * (end[0] - start[0]);
          const y = start[1] + 0.5 * (end[1] - start[1]);
          positions.push([x, y, angle]);
          noArrowDistance = 0;
        } else {
          noArrowDistance += distance;
        }
      } else {
        noArrowDistance = 0;
      }

      for (let j = 0; j < numArrows; j++) {
        const ratio = (j + 1) / (numArrows + 1); // Position of the arrow
        const x = start[0] + ratio * (end[0] - start[0]);
        const y = start[1] + ratio * (end[1] - start[1]);
        positions.push([x, y, angle]);
      }
    }

    return positions;
  };

  const vehicleTypesDropdown = () => {
    let allChecker = true;
    vehicleTypesArray.forEach((type) => {
      if (!vehiclePlotLinesDisplay.includes(type)) {
        allChecker = false;
      }
    });

    let vehicleTypesArrayDisplay = [];
    if (vehicleTypesArray.length > 1) {
      vehicleTypesArrayDisplay = vehicleTypesArray;
    }

    return (
      <div>
        <div style={{textAlign: 'left', fontWeight: 'bold'}}>Show Plotlines</div>
        {renderVehicleTypeOption('all', '', 'Show All', allChecker)}
        {vehicleTypesArrayDisplay.map((type) => {
          return renderVehicleTypeOption(
            type,
            machineIconMapping[type],
            machineTypeMapping[type],
            vehiclePlotLinesDisplay.includes(type)
          );
        })}
        <Divider className='mt-3' sx={{backgroundColor: 'black'}} />
        <div className='mt-4' style={{textAlign: 'left', fontWeight: 'bold'}}>
          Advanced Controls
        </div>
        {renderClusterDisplayToggle()}
      </div>
    );
  };

  async function handleTypeChecked(event, type) {
    let displayedPlotLines;
    if (type == 'all' && event.target.checked) {
      dispatch(updateDisplayedPlotLines(machineTypeList));
      displayedPlotLines = machineTypeList;
    } else if (type == 'all' && !event.target.checked) {
      dispatch(updateDisplayedPlotLines([]));
      displayedPlotLines = [];
    } else if (event.target.checked && !vehiclePlotLinesDisplay.includes(type)) {
      dispatch(updateDisplayedPlotLines([...vehiclePlotLinesDisplay, type]));
      displayedPlotLines = [...vehiclePlotLinesDisplay, type];
    } else if (vehiclePlotLinesDisplay.includes(type)) {
      const index = vehiclePlotLinesDisplay.indexOf(type);
      const newVehiclePlotLinesDisplay = [...vehiclePlotLinesDisplay];
      newVehiclePlotLinesDisplay.splice(index, 1);
      dispatch(updateDisplayedPlotLines(newVehiclePlotLinesDisplay));
      displayedPlotLines = newVehiclePlotLinesDisplay;
    }

    socket.emit('updateDisplayedPlotlines', {displayedPlotLines});
  }

  function renderVehicleTypeOption(type, img, text, check) {
    return (
      <div style={{textAlign: 'left'}} key={type}>
        <Checkbox
          key={type}
          checked={check}
          onChange={async (e) => {
            await handleTypeChecked(e, type);
          }}
        />
        {img && (
          <Box
            component='img'
            sx={{
              height: 25,
              width: 20,
              maxHeight: {xs: 25, md: 20},
              maxWidth: {xs: 20, md: 20},
            }}
            alt='Tractor'
            src={img}
          />
        )}
        {text}
      </div>
    );
  }

  function renderClusterDisplayToggle(type) {
    return (
      <div style={{textAlign: 'left'}} key={type}>
        <Checkbox
          checked={displayClusters}
          onChange={async (e) => {
            if (zoneInfoWindow != null) {
              zoneInfoWindow.close();
            }
            if (vehInfoWindow != null) {
              vehInfoWindow.close();
            }
            dispatch(updateDisplayClusters(!displayClusters));
            socket.emit('updateDisplayClusters', {displayClusters: !displayClusters});
          }}
        />
        Cluster Bubbles
      </div>
    );
  }

  async function refreshSocket() {
    // Perform a socket refresh
    const socketRefreshCon = await socket.connect();
    // console.log('Socket Refreshed: ', socketRefreshCon.id, socketRefreshCon.connected);

    // Trigger a cabview data stream refresh
    dispatch(setRefreshCabviewDataStream(true));
  }

  function updateMap() {
    if (typeof vehInfoWindow == 'undefined' || vehInfoWindow == null) {
      const vehInfoWindowTemp = new google.maps.InfoWindow();
      setVehInfoWindow(vehInfoWindowTemp);
    }

    if (typeof zoneInfoWindow == 'undefined' || zoneInfoWindow == null) {
      const zoneInfoWindowTemp = new google.maps.InfoWindow();
      setZoneInfoWindow(zoneInfoWindowTemp);
    }

    // Add new hazard markers to map
    const newHazMarker = [];
    for (let i = 0; i < hazardData.length; i++) {
      const hazard = hazardData[i];
      newHazMarker.push(hazard);
    }
    setHazardMarkers(newHazMarker);

    // Vehicle Markers
    const vehicleMarkers = [];
    let selectedVehicleMarker;
    const pathLines = [];
    let selectedVehiclePathLines;

    for (let i = 0; i < vehicleData.length; i++) {
      if (
        vehicleData[i].gpsCoords == undefined ||
        vehicleData[i].gpsCoords.length <= 0 ||
        vehicleData[i]?.cabviewActive === false
      ) {
        continue;
      }
      const vehicle = vehicleData[i];

      // Determine if vehicle is the selected vehicle in cabview
      const selected = selectedVehicle == vehicle.serialNumber;

      const latestGpsPoint = vehicle.gpsCoords[vehicle.gpsCoords.length - 1];

      // Create infowindow for when marker is clicked on
      const vehicleSpeed =
        unitsLengthSystem == 'imperial'
          ? `${unitsLengthDisplayConversion(vehicle.speed, 'mph').toFixed(1)} mph`
          : `${vehicle.speed.toFixed(1)} km/h`;
      const contentDate = DateTime.fromISO(vehicle.gpsCoords[vehicle.gpsCoords.length - 1].dateTime);
      const contentDateString = contentDate
        .setZone(customerSettings.general.timeZone)
        .toLocaleString(DateTime.DATETIME_SHORT);

      const infoWindowContentString =
        `<div><b>${vehicle.name}</b></div>` +
        `<div style="font-size:10px"><b>Speed:</b> ${vehicleSpeed}</div>` +
        `<div style="font-size:10px"><b>Task:</b> ${vehicle.latestTaskName}</div>` +
        `<div style="font-size:10px"><b>Last Communicated:</b> ${contentDateString}</div>`;

      const vehiclePathLines = [];
      // Check if vehicle type in (array of allowed vehicle types)
      const vehicleTypeShowPathline =
        vehiclePlotLinesDisplay.includes(vehicle.machineType) ||
        (vehicle.machineType == undefined && vehiclePlotLinesDisplay.includes(0));

      if (vehicle.gpsCoords.length > 1) {
        // Add new OR update vehicle pathLine
        if (vehicleTypeShowPathline) {
          for (let j = 1; j < vehicle.gpsCoords.length; j++) {
            const lineObj = {
              start: [vehicle.gpsCoords[j - 1].longitude, vehicle.gpsCoords[j - 1].latitude],
              end: [vehicle.gpsCoords[j].longitude, vehicle.gpsCoords[j].latitude],
              vehicleSN: vehicle.serialNumber,
            };
            vehiclePathLines.push(lineObj);
          }
        }
      }

      const vehicleMarkerData = {
        name: vehicle.name,
        serialNumber: vehicle.serialNumber,
        bearing: -vehicle.bearing || 0,
        position: [latestGpsPoint.longitude, latestGpsPoint.latitude],
        coordinates: [latestGpsPoint.longitude, latestGpsPoint.latitude],
        machineType: vehicle.machineType,
        vehicleSpeed: vehicleSpeed,
        dateString: contentDateString,
        infoWindowContent: infoWindowContentString,
        selected: selected,
      };
      if (selected) {
        selectedVehicleMarker = vehicleMarkerData;
        selectedVehiclePathLines = vehiclePathLines;
      } else {
        vehicleMarkers.push(vehicleMarkerData);
        pathLines.push(...vehiclePathLines);
      }
    }
    if (selectedVehicleMarker) {
      // push selected vehicle's marker to the end of array to ensure it renders on top of other markers
      vehicleMarkers.push(selectedVehicleMarker);
    }
    if (selectedVehiclePathLines) {
      pathLines.push(...selectedVehiclePathLines);
    }
    setPathLineData(pathLines);
    setVehicleMarkers(vehicleMarkers);

    // Adjust map after marker update based on current map state
    if (mapState.locate) {
      locateVehicle();
    } else if (mapState.showAll) {
      showAllVehicles();
    }
  }

  function plotGeofences() {
    // SHOULD REALLY COMBINE ALL THE MAP COMPONENTS INTO ONE AND CLEAN UP
    // Check if blocks within zoneData
    if (!Object.prototype.hasOwnProperty.call(zonesDicts, 'blocks') || Object.keys(geofencesDict).length < 1) {
      return;
    }

    const ploygonArrayByiBlockNum = geofencePolygonsByIBlockNum;
    // Iterate through blocks
    Object.values(zonesDicts.blocks).forEach((block) => {
      const fieldName = zonesDicts.fields[block.fieldId] ? zonesDicts.fields[block.fieldId].name : '';
      const regionName = zonesDicts.regions[block.regionId] ? zonesDicts.regions[block.regionId].name : '';

      const blockIntelliBlockNums = block.intelliblockNums;

      // Iterate through each polygon for plotting
      for (let j = 0; j < blockIntelliBlockNums.length; j++) {
        if (!Object.prototype.hasOwnProperty.call(geofencesDict, blockIntelliBlockNums[j])) {
          continue;
        }

        const geofence = geofencesDict[blockIntelliBlockNums[j]];

        let currentIblockPolygons = [];
        if (
          Object.prototype.hasOwnProperty.call(ploygonArrayByiBlockNum, blockIntelliBlockNums[j]) &&
          ploygonArrayByiBlockNum[blockIntelliBlockNums[j]].length == geofence.geometry.coordinates.length
        ) {
          // use previously drawn polygon if number of polygons matches iblock geofence's definition
          currentIblockPolygons = ploygonArrayByiBlockNum[blockIntelliBlockNums[j]];
        } else {
          const latLngList = [];
          let maxMinPoints = [];
          let maxLatPoint;
          let maxLngPoint;
          let minLatPoint;
          let minLngPoint;
          // Iterate through each coordinate set in the polygon.
          // Sets after the first one correspond to holes inside the polygon
          for (let k = 0; k < geofence.geometry.coordinates.length; k++) {
            const coordinates = geofence.geometry.coordinates[k];
            const latLngMap = coordinates.map((coordPair) => {
              const tempPoint = {lat: coordPair[1], lng: coordPair[0]};
              if (!maxLatPoint || tempPoint.lat > maxLatPoint.lat) {
                maxLatPoint = tempPoint;
              }
              if (!maxLngPoint || tempPoint.lng > maxLngPoint.lng) {
                maxLngPoint = tempPoint;
              }
              if (!minLatPoint || tempPoint.lat < minLatPoint.lat) {
                minLatPoint = tempPoint;
              }
              if (!minLngPoint || tempPoint.lng < minLngPoint.lng) {
                minLngPoint = tempPoint;
              }
              return tempPoint;
            });
            latLngList.push(latLngMap);
          }

          if (maxLatPoint && maxLngPoint && minLatPoint && minLngPoint) {
            maxMinPoints = [maxLatPoint, maxLngPoint, minLatPoint, minLngPoint];
          }

          let rowSpacingWithUnit = 'N/A';
          if (geofence.rowSpacingMeters > 0) {
            rowSpacingWithUnit =
              unitsLengthSystem == 'imperial'
                ? `${unitsLengthDisplayConversion(geofence.rowSpacingMeters, 'ft').toFixed(2)} ft`
                : `${geofence.rowSpacingMeters.toFixed(2)} m`;
          }

          // Convert acreage area to desired units
          let unitsArea = 'ac';
          if (unitsAreaSystem == 'hectare') {
            unitsArea = 'ha';
          }

          const convertedAcreage = unitsAreaConversion(block.areaMeters2, unitsArea, 'meters2');
          const acreageWithUnit = `${convertedAcreage.toFixed(2)} ${unitsArea}`;

          // Create polygon object
          const polygon = new google.maps.Polygon({
            paths: latLngList,
            strokeColor: zoneColorsList[zoneColorIndex],
            strokeWeight: 0.5,
            strokePosition: google.maps.StrokePosition.INSIDE,
            fillColor: zoneColorsList[zoneColorIndex],
            fillOpacity: 0.3,
            zIndex: -1,
            reiActive: false,
            maxMinPoints: maxMinPoints,
            blockName: block.name,
            fieldName: fieldName,
            regionName: regionName,
            rowSpacingWithUnit: rowSpacingWithUnit,
            acreageWithUnit: acreageWithUnit,
          });

          polygon.setMap(null);
          currentIblockPolygons.push(polygon);
        }

        // Determine if REI warning should show
        let reiWarningShow = false;
        let remRei = 0;
        if (Object.prototype.hasOwnProperty.call(reiActiveDict, block.id)) {
          const reiObj = reiActiveDict[block.id];
          const reiEndTime = DateTime.fromISO(reiObj.exitTime['@ts']).plus({hours: reiObj.reiHours});
          remRei = reiEndTime.diff(DateTime.now(), 'hours').toObject()?.hours;

          // Final check to ensure rei interval is valid
          if (remRei && remRei > 0 && remRei <= reiObj.reiHours) {
            reiWarningShow = true;
            remRei = Math.ceil(remRei);
          }
        }
        let strokeColor = zoneColorsList[zoneColorIndex];
        let strokeWeight = 0.5;
        let fillColor = zoneColorsList[zoneColorIndex];
        let reiActive = false;
        if (reiWarningShow) {
          strokeColor = '#ff0000';
          strokeWeight = 6;
          fillColor = '#af4a4d';
          reiActive = true;
        }

        currentIblockPolygons.forEach((iblockPolygon) => {
          // Update polygon object
          iblockPolygon.setOptions({
            strokeColor: strokeColor,
            strokeWeight: strokeWeight,
            fillColor: fillColor,
          });
          iblockPolygon.reiActive = reiActive;

          // Add OR Update Polygon info window
          if (iblockPolygon != undefined) {
            let infoWindowContent = `<div><b>${block.name}</b></div>`;

            // Display remaining rei
            if (reiWarningShow) {
              infoWindowContent +=
                `<div style="font-size:12px;color:red"><b>DO NOT ENTER</b></div>` +
                `<div style="font-size:10px;color:red"><b>Remaining REI:</b> ${remRei} hours</div>`;
            }

            // Display field and region
            infoWindowContent +=
              `<div style="font-size:10px"><b>Field:</b> ${iblockPolygon.fieldName}</div>` +
              `<div style="font-size:10px"><b>Region:</b> ${iblockPolygon.regionName}</div>`;

            // Display zone info
            if (zoneInfoEnabled) {
              const areaLabel = unitsAreaSystem == 'hectare' ? 'Hectares' : 'Acreage';
              infoWindowContent +=
                `<div style="font-size:10px"><b>Row Spacing:</b> ${iblockPolygon.rowSpacingWithUnit}</div>` +
                `<div style="font-size:10px"><b>${areaLabel}:</b> ${iblockPolygon.acreageWithUnit}</div>`;
            }

            google.maps.event.clearListeners(iblockPolygon, 'click');
            iblockPolygon.addListener('click', (event) => {
              zoneInfoWindow.setContent(infoWindowContent);
              zoneInfoWindow.setPosition(event.latLng);
              zoneInfoWindow.open({
                map,
                shouldFocus: false,
              });
            });
          }
        });

        ploygonArrayByiBlockNum[blockIntelliBlockNums[j]] = currentIblockPolygons;
      }
    });

    setGeofencePolygonsByIBlockNum(ploygonArrayByiBlockNum);
  }

  function locateVehicle() {
    const minVehicleZoomLevel = 18;
    // Zoom into the selected vehicle
    if (vehicleMarkers.length > 0 && selectedVehicle) {
      const selectedVehicleMarkerObj = vehicleMarkers.find((markerObj) => {
        return selectedVehicle == markerObj.serialNumber;
      });
      if (selectedVehicleMarkerObj) {
        // autoZoomInProgress prevents map event listeners from triggering
        autoZoomInProgress = true;
        if (mapZoomLevel < minVehicleZoomLevel) {
          map.setZoom(minVehicleZoomLevel);
        }
        map.panTo(
          new google.maps.LatLng(selectedVehicleMarkerObj.coordinates[1], selectedVehicleMarkerObj.coordinates[0])
        );
        autoZoomInProgress = false;
      }
    }
  }

  function showAllVehicles() {
    // Fit all markers on map into view
    if (vehicleMarkers.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      for (let i = 0; i < vehicleMarkers.length; i++) {
        const vehicleMarkerObj = vehicleMarkers[i];
        bounds.extend(new google.maps.LatLng(vehicleMarkerObj.coordinates[1], vehicleMarkerObj.coordinates[0]));
      }
      // autoZoomInProgress prevents map event listeners from triggering
      autoZoomInProgress = true;
      map.fitBounds(bounds);
      autoZoomInProgress = false;
    }
  }

  function changeZonesColor() {
    // Update color index
    const newZoneColorIndex = (zoneColorIndex + 1) % zoneColorsList.length;
    const geofencesIBlockNums = Object.keys(geofencePolygonsByIBlockNum);
    for (let i = 0; i < geofencesIBlockNums.length; i++) {
      const iblockPolygons = geofencePolygonsByIBlockNum[geofencesIBlockNums[i]];
      iblockPolygons.forEach((polygon) => {
        if (polygon.reiActive) {
          return;
        }
        polygon.setOptions({
          strokeColor: zoneColorsList[newZoneColorIndex],
          fillColor: zoneColorsList[newZoneColorIndex],
        });
      });
    }
    setZoneColorIndex(newZoneColorIndex);
  }

  function changeZoneLabels() {
    const zoneInfoEnabled =
      typeof userSettings.general.zoneInfoEnabled != 'undefined' && userSettings.general.zoneInfoEnabled;
    const labelCycleLength = zoneInfoEnabled ? 6 : 4;
    setZoneLabelsIndex((zoneLabelsIndex + 1) % labelCycleLength);
  }

  function pathVisualConfigArbitration() {
    return {
      getWidth: 1,
      opacity: 0.8,
      widthUnits: 'meters',
      widthMinPixels: 2,
      widthMaxPixels: 3,
      pickable: false,
      autoHighlight: false,
    };
  }

  function generateClusterInfoWindow(markerObj) {
    let clusterContentString = '';
    const markerSorted = markerObj.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    for (let i = 0; i < markerSorted.length; i++) {
      const vehicleMarkerObj = markerSorted[i];

      const contentString =
        `${i > 0 ? '<br/>' : ''}` +
        `${vehicleMarkerObj.infoWindowContent}` +
        `<button class="btn btn-sm btn-success iw-btn" data-vehiclesn="${vehicleMarkerObj.serialNumber}" disabled=${
          vehicleMarkerObj.selected ? 'true' : 'false'
        }>${vehicleMarkerObj.selected ? 'Selected' : 'Select Vehicle'}</button>` +
        `<div><br/></div>`;

      clusterContentString += contentString;
    }
    return clusterContentString;
  }

  function updateClusterInfoWindowButtons() {
    const infoWindowSelectButtons = document.querySelectorAll('.iw-btn');
    for (let i = 0; i < infoWindowSelectButtons.length; i++) {
      const currentSelectBtnElement = infoWindowSelectButtons[i];
      const vehicleSN = currentSelectBtnElement.getAttribute('data-vehiclesn');
      if (!taskActive && selectedVehicle != vehicleSN) {
        currentSelectBtnElement.addEventListener('click', (e) => {
          setClusterSelectedVehicle(vehicleSN);
          vehInfoWindow.close();
        });
        currentSelectBtnElement.disabled = false;
      } else {
        currentSelectBtnElement.disabled = true;
      }
      if (selectedVehicle == vehicleSN) {
        currentSelectBtnElement.innerHTML = `Selected`;
      }
    }
  }

  function pathColorArbitration(pathObj) {
    let color = [15, 255, 0];
    if (selectedVehicle == pathObj.vehicleSN) {
      color = taskActive ? [255, 0, 0] : [246, 194, 62];
    }
    return color;
  }

  const vehicleMarkerProps = {
    id: 'icon',
    data: vehicleMarkers,
    getPosition: (d) => {
      return d.position;
    },
  };

  const vehicleMarkerClusterLayer = new IconClusterLayer({
    ...vehicleMarkerProps,
    id: 'icon-cluster',
    sizeScale: 40,
    visible: displayClusters,
    pickable: true,
    machineIconMapping: machineIconMapping,
    machineIconImageMapping: machineIconImageMapping,
    zoom: 0 || mapZoomLevel,
  });

  // Layers for map
  const layers = [
    new GeoJsonLayer({
      id: 'geofences',
      data: geofenceLayerData,
      visible: displayZones,
      stroked: true,
      filled: true,
      pickable: true,
      getFillColor: (geofence) => {
        let hex = zoneColorsList[zoneColorIndex];
        if (geofence.reiWarningShow) {
          hex = '#af4a4d';
        }
        const rgb = hex.match(/[0-9a-f]{2}/g).map((x) => {
          return parseInt(x, 16);
        });
        rgb.push(70); // Opacity
        return rgb;
      },
      getLineColor: (geofence) => {
        let hex = zoneColorsList[zoneColorIndex];
        if (geofence.reiWarningShow) {
          hex = '#ff0000';
        }
        const rgb = hex.match(/[0-9a-f]{2}/g).map((x) => {
          return parseInt(x, 16);
        });
        return rgb;
      },
      lineWidthMaxPixels: 15,
      getLineWidth: (geofence) => {
        let strokeWeight = 0.2;
        if (geofence.reiWarningShow) {
          strokeWeight = 8;
        }
        return strokeWeight;
      },
      onClick: (clickedObj) => {
        const geofence = clickedObj.object;

        // Create info window content
        let infoWindowContent = `<div><b>${geofence.blockName}</b></div>`;

        // Display remaining rei
        if (geofence.reiWarningShow) {
          infoWindowContent +=
            `<div style="font-size:12px;color:red"><b>DO NOT ENTER</b></div>` +
            `<div style="font-size:10px;color:red"><b>Remaining REI:</b> ${geofence.remRei} hours</div>`;
        }

        // Display field and region
        infoWindowContent +=
          `<div style="font-size:10px"><b>Field:</b> ${geofence.fieldName}</div>` +
          `<div style="font-size:10px"><b>Region:</b> ${geofence.regionName}</div>`;

        if (Object.prototype.hasOwnProperty.call(geofence, 'notes')) {
          infoWindowContent += `<div style="font-size:10px"><b>Notes:</b> ${geofence.notes}</div>`;
        }

        // Display zone info
        if (zoneInfoEnabled) {
          const areaLabel = unitsAreaSystem == 'hectare' ? 'Hectares' : 'Acreage';
          infoWindowContent +=
            `<div style="font-size:10px"><b>Row Spacing:</b> ${geofence.rowSpacingWithUnit}</div>` +
            `<div style="font-size:10px"><b>${areaLabel}:</b> ${geofence.acreageWithUnit}</div>`;
        }

        if (geofence) {
          zoneInfoWindow.setContent(infoWindowContent);
          zoneInfoWindow.setPosition(new google.maps.LatLng(clickedObj.coordinate[1], clickedObj.coordinate[0]));
          zoneInfoWindow.open({
            map,
            shouldFocus: false,
          });
        } else {
          zoneInfoWindow.close();
        }
      },
      updateTriggers: {
        getFillColor: [zoneColorIndex],
        getLineColor: [zoneColorIndex],
      },
    }),

    /* Pathline Layers */
    new LineLayer({
      id: `pathLine`,
      data: pathLineData,
      visible: true,
      getColor: (d) => {
        return pathColorArbitration(d);
      },
      getSourcePosition: (d) => {
        return d.start;
      },
      getTargetPosition: (d) => {
        return d.end;
      },
      ...pathVisualConfigArbitration(),
    }),
    new IconLayer({
      id: `pathArrows`,
      data: pathArrowData,
      visible: true,
      getIcon: () => {
        return {
          url: '/img/arrow.png', // Your arrow icon URL
          width: 200,
          height: 200,
        };
      },
      getPosition: (d) => {
        return [d.position[0], d.position[1]];
      },
      getAngle: (d) => {
        return d.position[2];
      },
      sizeScale: 8,
      sizeUnits: 'meters',
      sizeMinPixels: 0,
      sizeMaxPixels: 15,
    }),

    /* Vehicle Markers */
    new IconLayer({
      id: 'vehicleMarkers',
      data: vehicleMarkers,
      visible: !displayClusters,
      pickable: true,
      getSize: 55,
      getIcon: (vehicleMarkerData) => {
        // Determine icon based on vehicle type
        let iconUrl = machineIconMapping[vehicleMarkerData.machineType];

        // Add outline to icon
        if (selectedVehicle == vehicleMarkerData.serialNumber) {
          const img = machineIconImageMapping[vehicleMarkerData.machineType];
          const offset = 3; // Offset for outline
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = img.width + offset * 2;
          canvas.height = img.height + offset * 2;
          context.drawImage(img, offset, offset);

          // Loop to offset shadow in both x and y direction to generate outline
          for (let x = -offset; x <= offset; x++) {
            for (let y = -offset; y <= offset; y++) {
              context.shadowColor = '#ff9900';
              context.shadowBlur = 0;
              context.shadowOffsetX = x;
              context.shadowOffsetY = y;
              context.drawImage(img, offset, offset);
            }
          }
          iconUrl = canvas.toDataURL();
        }

        return {
          url: iconUrl,
          width: 256,
          height: 256,
        };
      },
      getPosition: (vehicleMarkerData) => {
        return vehicleMarkerData.position;
      },
      getAngle: (vehicleMarkerData) => {
        return vehicleMarkerData.bearing;
      },
      onClick: (clickedObj) => {
        const vehicleMarker = clickedObj.object;
        vehicleMarkerOnClickHandler(vehicleMarker);
      },
      updateTriggers: {
        getIcon: [selectedVehicle],
      },
    }),

    /* Vehicle Markers Cluster */
    vehicleMarkerClusterLayer,

    /* Hazard Marker Layer */
    new IconLayer({
      id: 'hazardMarkers',
      data: hazardMarkers,
      visible: mapZoomLevel > 14,
      pickable: true,
      getSize: 40,
      getIcon: (d) => {
        return {
          url: '/img/warning_icon.png',
          width: 32,
          height: 32,
        };
      },
      getPosition: (d) => {
        return [d.hazardCoordinate.lng, d.hazardCoordinate.lat];
      },
      onClick: (clickedObj) => {
        const marker = clickedObj.object;
        hazardMarkerOnClickHandler(marker);
      },
    }),

    /* Zone Labels, Go last to overlay everything */
    new TextLayer({
      id: 'labels',
      data: labelLayerData,
      visible: displayZones && zoneLabelsIndex != (zoneInfoEnabled ? 5 : 3) && mapZoomLevel > 14,
      getPosition: (labelData) => {
        return labelData.position;
      },
      getText: (labelData) => {
        let labelText = labelData.blockName;
        if (zoneLabelsIndex == 1) {
          labelText = labelData.fieldName;
        } else if (zoneLabelsIndex == 2) {
          labelText = labelData.regionName;
        } else if (zoneLabelsIndex == 3) {
          labelText = `Row spacing: ${labelData.rowSpacingWithUnit}`;
        } else if (zoneLabelsIndex == 4) {
          const areaLabel = unitsAreaSystem == 'hectare' ? 'Hectares' : 'Acreage';
          labelText = `${areaLabel}: ${labelData.acreageWithUnit}`;
        } else {
          // Default to block name if index is invalid
          labelText = labelData.blockName;
        }

        if (labelData.reiWarningShow) {
          labelText += `\n \nRemaining REI: ${labelData.remRei}h`;
        }

        return labelText;
      },
      getColor: (labelData) => {
        let textColor = [0, 0, 0];
        if (labelData.reiWarningShow) {
          textColor = [200, 0, 0];
        }
        return textColor;
      },
      background: true,
      backgroundPadding: [3, 3],
      getBackgroundColor: [255, 255, 255, 128],
      getSize: 12,
      maxWidth: 6,
      fontFamily: 'sans-serif',
      fontWeight: 'bold',
      getTextAnchor: 'middle',
      getAlignmentBaseline: 'center',
      updateTriggers: {
        getText: [zoneLabelsIndex],
      },
    }),
  ];

  return (
    <React.Fragment>
      <Map
        defaultCenter={{lat: 37.9718, lng: -122.7651}}
        defaultZoom={9}
        minZoom={5}
        tilt={0}
        mapTypeId={'hybrid'} // Set map type to satellite
        gestureHandling={'greedy'}
        disableDefaultUI={true} // Optional: removes default UI controls
        styles={[
          {
            featureType: 'poi',
            stylers: [{visibility: 'off'}],
          },
        ]} // Optional: empty styles array to ensure no style overrides
        reuseMaps={false}
      >
        <DeckGLOverlay
          layers={layers}
          onLoad={() => {
            setLoaded(true);
          }}
          onClick={(info) => {
            if (info?.sourceLayer?.id && info?.sourceLayer?.id == 'icon-cluster-icon' && info?.picked) {
              onClusterLayerHandler(info, map);
            }
            if (!info.layer) {
              vehInfoWindow.close();
            }
          }}
        />
        <MapControlButtons
          displayZones={displayZones}
          setDisplayZones={setDisplayZones}
          changeZoneLabels={changeZoneLabels}
          changeZonesColor={changeZonesColor}
          setPlotLinesModalOpen={setPlotLinesModalOpen}
          refreshSocket={refreshSocket}
          mapState={mapState}
          setMapState={setMapState}
        />
      </Map>
      <Modal
        open={plotLinesModalOpen}
        onClose={() => {
          return setPlotLinesModalOpen(false);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={plotLinesDisplayBoxStyle}>{vehicleTypesDropdown()}</Box>
      </Modal>
    </React.Fragment>
  );
}

export {CabViewMap};
