import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getCustomerAndUserInfo, checkAuthentication, getGlobalVehicleData} from '../../appSlice';
import {getSettingsData, updateSettingsGroups, getReportConfigsData} from './settingsSlice';
import {BulkUploadModal} from '../../components/BulkUploadModal';
import {
  searchFind,
  modalBoxStyle,
  hideCell,
  squareButton,
  roleValueToDisplayNameMapping,
  fetchPostAuthSafe,
  deepCopy,
  sortVehicleNamesHelper,
  machineTypeMapping,
} from '../../app/utils';
import {Tabs} from '../../components/Tabs';
import {TabContent} from '../../components/TabContent';
import {TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../components/Table';
import {Tailselect} from '../../components/Tailselect';
import {TabMenuTableWrapper} from '../../components/TabMenuTableWrapper';
import {CustomTablePagination} from '../../components/CustomTablePagination';
import {
  ModalSubmitting,
  SettingsSwitch,
  SettingsSelect,
  SettingsSearchSelect,
  SettingsChipInput,
  ViewOnlyText,
  SettingsText,
} from '../../components/GeneralComponents';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Modal,
  Box,
  Button,
  Checkbox,
  Switch,
  CircularProgress,
  Chip,
} from '@mui/material';

function UsersTab(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => {
    return state.settings.loading;
  });
  const databaseUsers = useSelector((state) => {
    return state.settings.databaseUsers;
  });
  const settingsGroups = useSelector((state) => {
    return state.settings.settingsGroups;
  });
  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const vehicles = useSelector((state) => {
    return state.settings.vehicles;
  });
  const zonesDicts = useSelector((state) => {
    return state.settings.zonesDicts;
  });
  const vehicleLabelsDict = useSelector((state) => {
    return state.settings.vehicleLabels;
  });

  const [submitting, setSubmitting] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalUser, setModalUser] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [sortMethod, setSortMethod] = useState('role');
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [updateProfileMulti, setUpdateProfileMulti] = useState(false);
  const [updateProductMulti, setUpdateProductMulti] = useState(false);

  const [activeVehicles, setActiveVehicles] = useState([]);
  const [settingsGroupError, setSettingsGroupError] = useState('');
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);

  const defaultSettingsGroupFormValues = {
    name: '',
    archived: false,
    cabviewEnabled: false,
    cropviewEnabled: false,
    shopviewEnabled: false,
    inspectionEnabled: false,
    zoneInfoEnabled: false,
    dashboardEnabled: false,
    alertviewEnabled: false,
    globalFiltersEnabled: false,
    includedVehicles: [],
    includedVehicleLabels: [],
    includedBlocks: [],
    includedFields: [],
    includedRegions: [],
  };

  const defaultUserSettingsFormValues = {
    username: [],
    email: '',
    role: '',
    archived: false,
    cabviewEnabled: false,
    cropviewEnabled: false,
    shopviewEnabled: false,
    inspectionEnabled: false,
    zoneInfoEnabled: false,
    dashboardEnabled: false,
    alertviewEnabled: false,
    globalFiltersEnabled: false,
    includedVehicles: [],
    includedVehicleLabels: [],
    includedBlocks: [],
    includedFields: [],
    includedRegions: [],
    settingsGroupIds: [],
    useSettingsGroup: false,
  };

  const [modalFormValues, setModalFormValues] = useState(defaultUserSettingsFormValues);
  const [settingGroupModalOpen, setSettingGroupModalOpen] = useState(false);
  const [modalGroup, setModalGroup] = useState(null);
  const [groupForDelete, setGroupForDelete] = useState(null);
  const [settingGroupModalFormValues, setSettingGroupModalFormValues] = useState(defaultSettingsGroupFormValues);

  const [filterOptions, setFilterOptions] = useState({
    userName: [],
    userEmail: [],
    role: [],
    status: [],
    group: [],
    settingsGroupName: [],
  });

  const [filters, setFilters] = useState({
    userName: [],
    userEmail: [],
    role: [],
    status: [],
    group: [],
    settingsGroupName: [],
  });

  useEffect(async () => {
    const activeVehTemp = [];
    for (let i = 0; i < vehicles.length; i++) {
      if (vehicles[i].icActive) {
        activeVehTemp.push(vehicles[i]);
      }
    }

    activeVehTemp.sort((a, b) => {
      return sortVehicleNamesHelper(a.name, b.name);
    });

    setActiveVehicles(activeVehTemp);
  }, [vehicles]);

  function refreshData() {
    dispatch(getCustomerAndUserInfo());
    dispatch(getGlobalVehicleData());
    dispatch(getSettingsData());
  }

  function handleBulkUploadModalOpen(openState) {
    setBulkUploadModalOpen(openState);
  }

  function isValidEmail(email) {
    // Regular expression pattern for validating email addresses
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  const usersCsvColumns = [
    {
      key: 'userName',
      required: true,
      description: 'User Name used to login to IntelliCulture.',
    },
    {
      key: 'email',
      required: true,
      description: 'Email address of the User',
    },
    {
      key: 'password',
      required: true,
      description: 'Password for the User',
    },
    {
      key: 'role',
      required: false,
      description: `Role of User, Accepted Values: 'customer_admin or 'user', Default: 'user'`,
    },
  ];

  function validateAndMapBulkUploadedData(data, dupCheckObject) {
    const requiredColumns = usersCsvColumns
      .filter((column) => {
        return column.required;
      })
      .map((column) => {
        return column.key;
      });

    const noDupColumns = ['userName'];

    const defaultUser = {
      'userName': '',
      'email': '',
      'password': '',
      'role': 'user',
    };
    const tempDoc = {
      ...defaultUser,
      ...(Object.hasOwnProperty.call(data, 'userName') ? {userName: data['userName']} : {}),
      ...(Object.hasOwnProperty.call(data, 'email') ? {email: data['email']} : {}),
      ...(Object.hasOwnProperty.call(data, 'password') ? {password: data['password']} : {}),
    };

    const errors = [];
    const warnings = [];

    noDupColumns.forEach((col) => {
      if (!Object.keys(dupCheckObject).includes(col)) {
        dupCheckObject[col] = [];
        dupCheckObject[col].push(data[col]);
      } else if (dupCheckObject[col].includes(data[col])) {
        errors.push(`Duplicate ${col}`);
      } else {
        dupCheckObject[col].push(data[col]);
      }
    });

    if (Object.hasOwnProperty.call(data, 'role')) {
      try {
        const role = data['role'];
        if (!['customer_admin', 'user'].includes(role)) {
          warnings.push(
            `Please input a valid role: 'customer_admin' or 'user'. Or default 'user' will be applied, Entered value: ${data['role']}`
          );
        } else {
          tempDoc['role'] = role;
        }
      } catch {
        warnings.push(`Role input is invalid, Entered value: ${data['role']}`);
      }
    }

    if (Object.hasOwnProperty.call(data, 'email')) {
      try {
        const email = data['email'];
        const emailValid = isValidEmail(email);
        if (!emailValid) {
          errors.push('Email input is invalid, please enter a valid email address.');
        }
      } catch {
        errors.push(`Email input is invalid, Entered value: ${data['email']}`);
      }
    }

    for (let i = 0; i < requiredColumns.length; i++) {
      if (!data[requiredColumns[i]] || data[requiredColumns[i]] == '') {
        errors.push(`Column ${requiredColumns[i]} from CSV cannot be empty`);
      }
    }

    tempDoc.errors = errors;
    tempDoc.warnings = warnings;

    return tempDoc;
  }

  function bulkUploadDataPreview(rowData, index, totalCount) {
    return (
      <div className='card mb-2 text-left' key={index}>
        <div className='card-header row mx-0 px-0'>
          <div className='col-8'>
            Username: {rowData.userName}{' '}
            {rowData.errors && rowData.errors.length > 0 ? (
              <FontAwesomeIcon className='text-danger' icon='fas fa-warning' />
            ) : rowData.warnings && rowData.warnings.length > 0 ? (
              <FontAwesomeIcon className='text-warning' icon='fas fa-warning' />
            ) : (
              <FontAwesomeIcon className='text-success' icon='fas fa-check-circle' />
            )}
          </div>
          <div className='col-4 text-right'>
            {index + 1}/{totalCount}
          </div>
        </div>
        <div className='row mx-0 px-0'>
          <div className='col-md-6'>Email: {rowData.email}</div>
          <div className='col-md-6'>Role: {rowData.role}</div>
        </div>
        {((rowData.errors && rowData.errors.length > 0) || (rowData.warnings && rowData.warnings.length > 0)) && (
          <div className='card-footer'>
            {rowData.errors.map((errorMsg) => {
              return <div key={errorMsg}>{errorMsg}</div>;
            })}
            {rowData.warnings.map((errorMsg) => {
              return <div key={errorMsg}>{errorMsg}</div>;
            })}
          </div>
        )}
      </div>
    );
  }

  async function postBulkUsers(newUserDocs) {
    const parsedUserDocs = newUserDocs.map((user) => {
      return {
        params: {
          userName: user.userName,
          email: user.email,
          role: ['ic_admin', 'customer_admin', 'user'].includes(user.role) ? user.role : 'user',
        },
        password: user.password,
      };
    });

    const postData = {docsList: parsedUserDocs, uploadType: 'user'};
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify(postData),
    };
    const url = '/settings/bulkUpload';
    const response = await fetchPostAuthSafe(url, options, userSettings.username, userSettings.databaseName);
    const result = await response.json();

    if (result.status == 401) {
      navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
    }
    setSubmitting(false);
    return result;
  }

  function handleFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  function removeArchivedValues(arr) {
    const newArr = [];
    arr.forEach((id) => {
      if (settingsGroups.hasOwnProperty(id) && !settingsGroups[id].archived) {
        newArr.push(parseInt(id));
      }
    });
    return newArr;
  }

  useEffect(async () => {
    let user = modalUser;
    if (Array.isArray(user) && user.length == 1) {
      user = user[0];
    }
    const isMultiple = Array.isArray(user);
    const userData = databaseUsers.find((targetUser) => {
      return targetUser.username == user;
    });

    setModalFormValues({
      username: isMultiple ? modalUser : userData.username,
      email: isMultiple ? '' : userData.email,
      role: isMultiple ? 'user' : userData.role,
      archived: isMultiple ? false : userData.archived,
      cabviewEnabled: isMultiple ? false : userData.settings.general.cabviewEnabled,
      cropviewEnabled: isMultiple ? false : userData.settings.general.cropviewEnabled,
      shopviewEnabled: isMultiple ? false : userData.settings.general.shopviewEnabled,
      inspectionEnabled: isMultiple ? false : userData.settings.general.inspectionEnabled,
      zoneInfoEnabled: isMultiple ? false : userData.settings.general.zoneInfoEnabled,
      dashboardEnabled: isMultiple ? false : userData.settings.general.dashboardEnabled,
      alertviewEnabled: isMultiple ? false : userData.settings.general.alertviewEnabled,
      globalFiltersEnabled: isMultiple ? false : userData.settings.general.globalFiltersEnabled,
      includedVehicles: isMultiple ? [] : userData.settings.general.includedVehicles,
      includedVehicleLabels: isMultiple ? [] : userData.settings.general.includedVehicleLabels,
      includedBlocks: isMultiple ? [] : userData.settings.general.includedBlocks,
      includedFields: isMultiple ? [] : userData.settings.general.includedFields,
      includedRegions: isMultiple ? [] : userData.settings.general.includedRegions,
      updateProfileMulti: false,
      updateProductMulti: false,
      useSettingsGroup: isMultiple
        ? false
        : typeof userData.useSettingsGroup != 'undefined'
        ? userData.useSettingsGroup
        : false,
      settingsGroupIds: isMultiple
        ? []
        : typeof userData.settingsGroupIds != 'undefined'
        ? removeArchivedValues(userData.settingsGroupIds)
        : [],
    });

    setUpdateProfileMulti(false);
    setUpdateProductMulti(false);
  }, [modalUser]);

  useEffect(async () => {
    const userNameOptions = [];
    const userEmailOptions = [];
    const roleOptions = [];
    const groupOptions = [{value: '*', text: '*'}];
    const settingsGroupOptions = [];

    databaseUsers.forEach((user) => {
      userNameOptions.push(user.username);
      userEmailOptions.push(user.email);
      roleOptions.push(user.role);
    });

    Object.keys(settingsGroups).forEach((groupId) => {
      if (!settingsGroups.archived) {
        groupOptions.push({
          value: groupId,
          text: settingsGroups[groupId].name,
        });
        settingsGroupOptions.push({
          value: groupId,
          text: settingsGroups[groupId].name,
        });
      }
    });

    userNameOptions.sort((a, b) => {
      return a.localeCompare(b);
    });
    userEmailOptions.sort((a, b) => {
      return a.localeCompare(b);
    });
    roleOptions.sort((a, b) => {
      return a.localeCompare(b);
    });
    setFilterOptions({
      userName: userNameOptions,
      userEmail: userEmailOptions,
      role: [...new Set(roleOptions)].map((roleValue) => {
        return {
          value: roleValue,
          text: roleValueToDisplayNameMapping[roleValue],
        };
      }),
      status: ['Active', 'Archived'],
      group: groupOptions,
      settingsGroupName: settingsGroupOptions,
    });
    let tempTableData = [...databaseUsers].filter((user) => {
      return filterRow(user);
    });
    tempTableData = sortData(tempTableData);
    if (page > parseInt(tempTableData.length / rowsPerPage)) {
      setPage(0);
    }
    setTableData(tempTableData);
  }, [databaseUsers]);

  useEffect(async () => {
    let tempTableData = [...databaseUsers].filter((user) => {
      return filterRow(user);
    });
    tempTableData = sortData(tempTableData);
    if (page > parseInt(tempTableData.length / rowsPerPage)) {
      setPage(0);
    }
    setTableData(tempTableData);
  }, [filters, searchText, sortMethod]);

  const handleClickUser = (event, username) => {
    const newSelected = selectedUsers.slice();
    const userIdx = newSelected.indexOf(username);
    if (event.target.checked && userIdx == -1) {
      newSelected.push(username);
    } else if (!event.target.checked && userIdx != -1) {
      newSelected.splice(userIdx, 1);
    }
    setSelectedUsers(newSelected);
  };

  const handleClickAll = (event) => {
    const displayedUsernames = tableData.map((userData) => {
      return userData.username;
    });

    const newSelected = [];
    databaseUsers.forEach((user) => {
      if (
        (event.target.checked && displayedUsernames.includes(user.username)) ||
        (!displayedUsernames.includes(user.username) && selectedUsers.includes(user.username))
      ) {
        newSelected.push(user.username);
      }
    });
    setSelectedUsers(newSelected);
  };

  const checkClickAllIndeterminate = () => {
    const indeterminate = selectedUsers.length > 0 && !checkClickAllChecked();

    return indeterminate;
  };

  const checkClickAllChecked = () => {
    const allDisplayedAreSelected = tableData.every((userData) => {
      return selectedUsers.includes(userData.username);
    });

    const checked = selectedUsers.length > 0 && allDisplayedAreSelected;

    return checked;
  };

  const handleModalChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setModalFormValues((values) => {
      return {...values, [name]: value};
    });

    if (name == 'updateProfileMulti') {
      setUpdateProfileMulti(value);
    }
    if (name == 'updateProductMulti') {
      setUpdateProductMulti(value);
    }
  };

  function handleSetModalGroup(groupId) {
    setSettingGroupModalFormValues({
      ...defaultSettingsGroupFormValues,
      name: settingsGroups[groupId].name,
      ...settingsGroups[groupId].settings.general,
    });
    setModalGroup(groupId);
  }

  function handleCopyModalGroup(groupId) {
    setModalGroup('newGroup');
    setSettingGroupModalFormValues({
      ...defaultSettingsGroupFormValues,
      name: `${settingsGroups[groupId].name} - Copy`,
      ...settingsGroups[groupId].settings.general,
    });
  }

  function resetGroupModal() {
    setSettingGroupModalFormValues({...defaultSettingsGroupFormValues});
    setSettingsGroupError('');
    setModalGroup(null);
  }

  function handleGroupModalChange(event) {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setSettingGroupModalFormValues((values) => {
      return {...values, [name]: value};
    });
  }

  function filterRow(user) {
    const userStatus = user.archived ? 'Archived' : 'Active';
    // Convert Properties to string for search
    const rowObj = {
      name: user.username,
      email: user.email,
      role: user.role,
      status: userStatus,
    };

    const search = searchFind(<div>{JSON.stringify(rowObj)}</div>, searchText.toLowerCase().trim());
    const nameInFilter = filters.userName.includes(user.username) || filters.userName.length == 0;
    const emailInFilter = filters.userEmail.includes(user.email) || filters.userEmail.length == 0;
    const roleInFilter = filters.role.includes(user.role) || filters.role.length == 0;
    const statusInFilter = filters.status.includes(userStatus) || filters.status.length == 0;
    const settingsGroupIdsArray =
      user.hasOwnProperty('settingsGroupIds') && typeof user.settingsGroupIds != 'undefined'
        ? user.settingsGroupIds
        : [];
    let groupInFilter = filters.group.length == 0;

    settingsGroupIdsArray.forEach((groupId) => {
      if (filters.group.includes(groupId.toString())) {
        groupInFilter = true;
      }
    });

    if (settingsGroupIdsArray.length == 0 && filters.group.includes('*')) {
      groupInFilter = true;
    }

    const userInFilter = nameInFilter && emailInFilter && roleInFilter && statusInFilter && groupInFilter;

    return userInFilter && search;
  }

  function sortData(dataList) {
    // Make copy of list or else will get error when trying to sort
    const userList = JSON.parse(JSON.stringify(dataList));
    // Sort zones and vehicle list within zone by method specified
    // Will sort a after b if compare function returns > 0 and sort a before b if compare function returns < 0
    if (['username', 'email', 'role'].includes(sortMethod)) {
      userList.sort((a, b) => {
        return a[sortMethod].localeCompare(b[sortMethod]);
      });
    } else {
      userList.sort((a, b) => {
        return a[sortMethod] < b[sortMethod] ? 1 : -1;
      });
    }
    return userList;
  }

  async function handleDeleteGroup() {
    setSubmitting(true);

    const settingsGroupUpdateDoc = deepCopy(settingsGroups[groupForDelete]);
    settingsGroupUpdateDoc.archived = true;
    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({settingsGroupUpdateDoc: settingsGroupUpdateDoc}),
    };

    const response = await fetchPostAuthSafe(
      '/settings/updateSettingsGroup',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const result = await response.json();
    if (result && result.success == true) {
      dispatch(updateSettingsGroups(result.results));
      setSubmitting(false);
      resetGroupModal();
      setSettingGroupModalOpen(false);
    }
  }

  async function handleGroupModalSubmit() {
    setSubmitting(true);

    let makeRequest = false;
    let settingsGroupUpdateDoc = {
      id: 'newGroup',
      name: '',
      settings: {
        general: {
          includedVehicles: [],
          includedVehicleLabels: [],
          includedBlocks: [],
          includedFields: [],
          includedRegions: [],
          cabviewEnabled: false,
          cropviewEnabled: false,
          shopviewEnabled: false,
          inspectionEnabled: false,
          zoneInfoEnabled: false,
          dashboardEnabled: false,
          alertviewEnabled: false,
          globalFiltersEnabled: false,
        },
      },
    };
    // If we're editing an existing group initialize with the current group settings
    if (settingsGroups.hasOwnProperty(modalGroup)) {
      settingsGroupUpdateDoc = deepCopy(settingsGroups[modalGroup]);
    }
    if (settingsGroupUpdateDoc.name != settingGroupModalFormValues.name) {
      settingsGroupUpdateDoc.name = settingGroupModalFormValues.name;
      makeRequest = true;
    }

    Object.keys(settingsGroupUpdateDoc.settings.general).forEach((key) => {
      if (
        settingGroupModalFormValues.hasOwnProperty(key) &&
        settingsGroupUpdateDoc.settings.general[key] != settingGroupModalFormValues[key]
      ) {
        settingsGroupUpdateDoc.settings.general[key] = settingGroupModalFormValues[key];
        makeRequest = true;
      }
    });

    if (settingsGroupUpdateDoc.name == '') {
      setSettingsGroupError('Please enter a name for the settings group.');
      setSubmitting(false);
      return false;
    }

    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({settingsGroupUpdateDoc: settingsGroupUpdateDoc}),
    };
    let result;
    if ((makeRequest = true && settingsGroupUpdateDoc.id != 'newGroup')) {
      const response = await fetchPostAuthSafe(
        '/settings/updateSettingsGroup',
        options,
        userSettings.username,
        userSettings.databaseName
      );
      result = await response.json();
    } else if (settingsGroupUpdateDoc.id == 'newGroup') {
      const response = await fetchPostAuthSafe(
        '/settings/createSettingsGroup',
        options,
        userSettings.username,
        userSettings.databaseName
      );
      result = await response.json();
    }

    if (result.success == true) {
      dispatch(updateSettingsGroups(result.results));
      dispatch(checkAuthentication());
      dispatch(getCustomerAndUserInfo());
      dispatch(getGlobalVehicleData());
      dispatch(getSettingsData());
      setSubmitting(false);
      resetGroupModal();
      setSettingGroupModalOpen(false);
    } else if (result.success == false) {
      if (result.error == 'Duplicate') {
        setSettingsGroupError(`Settings Group with name ${settingsGroupUpdateDoc.name} already exists.`);
        setSubmitting(false);
      } else {
        setSettingsGroupError('Error updating settings group please try again or contact your system administrator');
        setSubmitting(false);
      }
    }
  }

  async function handleModalSubmit() {
    setSubmitting(true);

    // Convert user list to array if only one user
    let userList = modalFormValues.username;
    if (!Array.isArray(modalFormValues.username)) {
      userList = [modalFormValues.username];
    }

    const multiUserEditing = userList.length > 1;

    // List of update requests
    const requestsList = [];

    for (let i = 0; i < userList.length; i++) {
      const username = userList[i];
      let updateDoc = {};
      let options = {};

      // Find target user
      const updateTargetUser = databaseUsers.find((targetUser) => {
        return targetUser.username == username;
      });
      if (!updateTargetUser) {
        continue;
      }

      // Profile
      if (userSettings.roleAccess['teamManagement']) {
        if ((multiUserEditing && updateProfileMulti) || !multiUserEditing) {
          updateDoc = {
            username: updateTargetUser.username,
          };

          if (updateTargetUser.role != modalFormValues.role) {
            updateDoc.role = modalFormValues.role;
          }

          if (updateTargetUser.archived != modalFormValues.archived) {
            updateDoc.archived = modalFormValues.archived;
          }

          if (updateTargetUser.useSettingsGroup != modalFormValues.useSettingsGroup) {
            updateDoc.useSettingsGroup = modalFormValues.useSettingsGroup;
          }

          if (updateTargetUser.settingsGroupIds != modalFormValues.settingsGroupIds) {
            updateDoc.settingsGroupIds = modalFormValues.settingsGroupIds.map((id) => {
              return parseInt(id);
            });
          }

          if (Object.keys(updateDoc).length > 1) {
            options = {
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify(updateDoc),
            };
            requestsList.push(
              fetchPostAuthSafe(
                '/settings/updateUserAccount',
                options,
                userSettings.username,
                userSettings.databaseName
              )
            );
          }
        }
      }

      // Product
      if (userSettings.roleAccess['generalManagement']) {
        if ((multiUserEditing && updateProductMulti) || !multiUserEditing) {
          updateDoc = {
            username: updateTargetUser.username,
          };

          const generalBooleansAndStrings = [
            'cabviewEnabled',
            'cropviewEnabled',
            'shopviewEnabled',
            'inspectionEnabled',
            'zoneInfoEnabled',
            'dashboardEnabled',
            'alertviewEnabled',
            'globalFiltersEnabled',
            'includedVehicles',
            'includedBlocks',
            'includedFields',
            'includedRegions',
            'includedVehicleLabels',
          ];

          for (let i = 0; i < generalBooleansAndStrings.length; i++) {
            if (
              updateTargetUser.settings.general[generalBooleansAndStrings[i]] !=
              modalFormValues[generalBooleansAndStrings[i]]
            ) {
              updateDoc[generalBooleansAndStrings[i]] = modalFormValues[generalBooleansAndStrings[i]];
            }
          }

          if (Object.keys(updateDoc).length > 1) {
            options = {
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify(updateDoc),
            };
            requestsList.push(
              fetchPostAuthSafe(
                '/settings/updateUserGeneralSettings',
                options,
                userSettings.username,
                userSettings.databaseName
              )
            );
          }
        }
      }
    }

    // Make all calls
    // await Promise.all(requestsList);
    const responses = await Promise.all(requestsList);
    const responsesJsons = responses.map((response) => {
      return response.json();
    });
    const results = await Promise.all(responsesJsons);
    for (let i = 0; i < results.length; i++) {
      if (results[i].status == 401) {
        navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
        break;
      } else if (results[i].errorMsg) {
        navigate('/error', {state: {errorMsg: results[i].errorMsg}});
        break;
      }
    }
    dispatch(checkAuthentication());
    dispatch(getCustomerAndUserInfo());
    dispatch(getGlobalVehicleData());
    dispatch(getSettingsData());
    dispatch(getReportConfigsData());
    setModalOpen(false);
    setSubmitting(false);
    setModalUser([]);
  }

  function generateModal() {
    const tabs = ['Profile'];
    const icons = ['fas fa-user'];

    if (userSettings.roleViewAccess['generalManagement']) {
      tabs.push('User Product');
      icons.push('fas fa-sliders');
    }
    const viewOnlyProfile = !userSettings.roleAccess['teamManagement'];
    const viewOnlyProduct = !userSettings.roleAccess['generalManagement'];

    return (
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setModalUser([]);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='overflow-auto'
      >
        <Box sx={modalBoxStyle}>
          {submitting ? (
            <ModalSubmitting />
          ) : (
            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
              <h3>
                <strong>User Settings</strong>
              </h3>
              {Array.isArray(modalFormValues.username) && (
                <div>
                  <h6 style={{fontWeight: 'bold', color: 'black'}}>Editing multiple accounts</h6>
                  <h6 style={{fontWeight: 'bold', color: 'black'}}>
                    Please confirm update should apply for all users.
                  </h6>
                </div>
              )}
              <Tabs tabs={tabs} icons={icons} />
              <TabContent
                content={[
                  // Profile Tab
                  <React.Fragment key={'Profile'}>
                    {viewOnlyProfile && <ViewOnlyText />}
                    <Table aria-label='simple table'>
                      <TableBody>
                        {Array.isArray(modalUser) && modalUser.length > 1 && (
                          <TableRow>
                            <TableCell>
                              <div style={{fontWeight: 'bold'}}>Update Profiles </div>
                              <div style={{fontWeight: 'bold'}}>
                                For Multiple Users <span style={{color: 'red'}}>*</span>
                              </div>
                            </TableCell>
                            <TableCell>
                              <Switch
                                name='updateProfileMulti'
                                checked={modalFormValues.updateProfileMulti}
                                onChange={handleModalChange}
                                disabled={viewOnlyProfile}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell>Username</TableCell>
                          <TableCell>
                            {Array.isArray(modalFormValues.username)
                              ? modalFormValues.username.join(', ')
                              : modalFormValues.username}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Email</TableCell>
                          <TableCell>{modalFormValues.email}</TableCell>
                        </TableRow>
                        <SettingsSwitch
                          label='Use Settings Group'
                          setting='useSettingsGroup'
                          formValues={modalFormValues}
                          handleChange={handleModalChange}
                          disabled={viewOnlyProduct}
                        />
                        <SettingsSearchSelect
                          label='Settings Groups'
                          setting='settingsGroupIds'
                          multiple
                          formValues={modalFormValues}
                          handleChange={handleModalChange}
                          disabled={viewOnlyProduct || !modalFormValues.useSettingsGroup}
                          options={Object.keys(settingsGroups).map((id) => {
                            if (settingsGroups.hasOwnProperty(id)) {
                              return {value: parseInt(id), label: settingsGroups[id].name};
                            }
                          })}
                        />
                        <SettingsSelect
                          label='Role'
                          setting='role'
                          formValues={modalFormValues}
                          handleChange={handleModalChange}
                          disabled={viewOnlyProfile}
                          options={[
                            {value: 'user', label: 'User'},
                            ...(userSettings.role == 'ic_admin' ||
                            userSettings.role == 'customer_admin' ||
                            modalFormValues.role == 'customer_admin'
                              ? [{value: 'customer_admin', label: 'Admin'}]
                              : []),
                            ...(userSettings.role == 'ic_admin' && modalFormValues.role == 'ic_admin'
                              ? [{value: 'ic_admin', label: 'IC Admin'}]
                              : []),
                          ]}
                        />
                        {!Array.isArray(modalFormValues.username) &&
                          modalFormValues.username != userSettings.username && (
                            <SettingsSwitch
                              label='Archive User'
                              setting='archived'
                              formValues={modalFormValues}
                              handleChange={handleModalChange}
                              disabled={viewOnlyProfile}
                            />
                          )}
                      </TableBody>
                    </Table>
                  </React.Fragment>,

                  // Product Tab
                  <React.Fragment key={'User Product'}>
                    {viewOnlyProduct && <ViewOnlyText />}
                    <Table size='small' aria-label='simple table'>
                      <TableBody>
                        {Array.isArray(modalUser) && modalUser.length > 1 && (
                          <TableRow>
                            <TableCell>
                              <div style={{fontWeight: 'bold'}}>Update Product </div>
                              <div style={{fontWeight: 'bold'}}>
                                For Multiple Users <span style={{color: 'red'}}>*</span>
                              </div>
                            </TableCell>
                            <TableCell>
                              <Switch
                                name='updateProductMulti'
                                checked={modalFormValues.updateProductMulti}
                                onChange={handleModalChange}
                                disabled={viewOnlyProduct || modalFormValues.useSettingsGroup}
                              />
                            </TableCell>
                          </TableRow>
                        )}

                        <SettingsSwitch
                          label='CabView Enabled'
                          setting='cabviewEnabled'
                          formValues={modalFormValues}
                          handleChange={handleModalChange}
                          disabled={viewOnlyProduct || modalFormValues.useSettingsGroup}
                        />
                        <SettingsSwitch
                          label='CropView Enabled'
                          setting='cropviewEnabled'
                          formValues={modalFormValues}
                          handleChange={handleModalChange}
                          disabled={viewOnlyProduct || modalFormValues.useSettingsGroup}
                        />
                        <SettingsSwitch
                          label='ShopView Enabled'
                          setting='shopviewEnabled'
                          formValues={modalFormValues}
                          handleChange={handleModalChange}
                          disabled={viewOnlyProduct || modalFormValues.useSettingsGroup}
                        />
                        <SettingsSwitch
                          label='Inspection Enabled'
                          setting='inspectionEnabled'
                          formValues={modalFormValues}
                          handleChange={handleModalChange}
                          disabled={viewOnlyProduct || modalFormValues.useSettingsGroup}
                        />
                        <SettingsSwitch
                          label='MapView Enabled'
                          setting='zoneInfoEnabled'
                          formValues={modalFormValues}
                          handleChange={handleModalChange}
                          disabled={viewOnlyProduct || modalFormValues.useSettingsGroup}
                        />
                        <SettingsSwitch
                          label='StatsView Enabled'
                          setting='dashboardEnabled'
                          formValues={modalFormValues}
                          handleChange={handleModalChange}
                          disabled={viewOnlyProduct || modalFormValues.useSettingsGroup}
                        />
                        <SettingsSwitch
                          label='AlertView Enabled'
                          setting='alertviewEnabled'
                          formValues={modalFormValues}
                          handleChange={handleModalChange}
                          disabled={viewOnlyProduct || modalFormValues.useSettingsGroup}
                        />
                        <SettingsSwitch
                          label='Global Filters Enabled'
                          setting='globalFiltersEnabled'
                          formValues={modalFormValues}
                          handleChange={handleModalChange}
                          disabled={viewOnlyProduct || modalFormValues.useSettingsGroup}
                        />
                        <SettingsSearchSelect
                          label='Included Vehicles'
                          setting='includedVehicles'
                          multiple
                          formValues={modalFormValues}
                          handleChange={handleModalChange}
                          disabled={viewOnlyProduct || modalFormValues.useSettingsGroup}
                          options={activeVehicles.map((veh) => {
                            return {
                              value: veh.serialNumber,
                              label: veh.name + (veh.archived ? ' (Archived)' : ''),
                              group: machineTypeMapping[veh.machineType],
                            };
                          })}
                        />
                        <SettingsSearchSelect
                          label='Included Vehicle Labels'
                          setting='includedVehicleLabels'
                          multiple
                          formValues={modalFormValues}
                          handleChange={handleModalChange}
                          disabled={viewOnlyProduct || modalFormValues.useSettingsGroup}
                          options={Object.keys(vehicleLabelsDict).map((groupId) => {
                            return {
                              value: groupId,
                              label:
                                vehicleLabelsDict[groupId].name +
                                (vehicleLabelsDict[groupId].archived ? ' (Archived)' : ''),
                            };
                          })}
                        />
                        <SettingsSearchSelect
                          label='Included Blocks'
                          setting='includedBlocks'
                          multiple
                          formValues={modalFormValues}
                          handleChange={handleModalChange}
                          disabled={viewOnlyProduct || modalFormValues.useSettingsGroup}
                          options={Object.values(zonesDicts.blocks).map((zone) => {
                            return {value: zone.id, label: zone.name};
                          })}
                        />
                        <SettingsSearchSelect
                          label='Included Fields'
                          setting='includedFields'
                          multiple
                          formValues={modalFormValues}
                          handleChange={handleModalChange}
                          disabled={viewOnlyProduct || modalFormValues.useSettingsGroup}
                          options={Object.values(zonesDicts.fields).map((zone) => {
                            return {value: zone.id, label: zone.name};
                          })}
                        />
                        <SettingsSearchSelect
                          label='Included Regions'
                          setting='includedRegions'
                          multiple
                          formValues={modalFormValues}
                          handleChange={handleModalChange}
                          disabled={viewOnlyProduct || modalFormValues.useSettingsGroup}
                          options={Object.values(zonesDicts.regions).map((zone) => {
                            return {value: zone.id, label: zone.name};
                          })}
                        />
                      </TableBody>
                    </Table>
                  </React.Fragment>,
                ]}
              />

              <div className='flex-row my-3 col-12 px-1'>
                <Button
                  variant='ic-button'
                  color='secondary'
                  className='mx-1'
                  onClick={() => {
                    setModalOpen(false);
                    setModalUser([]);
                  }}
                >
                  Cancel
                </Button>
                {(!viewOnlyProfile || !viewOnlyProduct) && (
                  <Button variant='ic-button' color='primary' className='mx-1' onClick={handleModalSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </div>
          )}
        </Box>
      </Modal>
    );
  }

  function generateMenu() {
    return (
      <div className='mt-2 pb-0'>
        {/* MANAGE USERS */}
        <h5 style={{color: 'black'}}>Manage Users</h5>
        <div className='row mx-0 my-1'>
          {userSettings.roleAccess['teamManagement'] && (
            <React.Fragment>
              <Button
                sx={squareButton}
                variant='ic-button'
                color='success'
                title='Add user'
                onClick={() => {
                  navigate('/create_user');
                }}
              >
                <FontAwesomeIcon icon='fas fa-plus' />
              </Button>
              <Button
                sx={squareButton}
                variant='ic-button'
                color='success'
                title='Bulk Upload'
                className='ml-1'
                onClick={() => {
                  handleBulkUploadModalOpen(true);
                }}
              >
                <FontAwesomeIcon icon='fas fa-upload' />
              </Button>
              <Button
                variant='ic-button'
                color='primary'
                title='Invite user'
                className='mx-1'
                onClick={() => {
                  navigate('/invite_user');
                }}
              >
                Invite
              </Button>
              <Button
                variant='ic-button'
                color='secondary'
                title='Edit users'
                className='mx-1'
                disabled={selectedUsers.length == 0}
                onClick={() => {
                  setModalOpen(true);
                  setModalUser(selectedUsers);
                }}
              >
                <FontAwesomeIcon icon='fas fa-edit' />
              </Button>
              <Button
                variant='ic-button'
                color='success'
                title='Settings Groups'
                onClick={() => {
                  setSettingGroupModalOpen(true);
                  setModalUser(selectedUsers);
                }}
              >
                Settings Groups
              </Button>
            </React.Fragment>
          )}
          <div className='px-1 px-md-1 dropdown' style={{maxWidth: '25%'}}>
            <button className='btn border-dark btn-light' data-toggle='dropdown' title='Sort'>
              <FontAwesomeIcon icon='fas fa-sort-amount-down' />
            </button>
            <div className='dropdown-menu border-dark' style={{minWidth: '0'}}>
              <div className='dropdown-header cropview-menu-text px-3'>Sort By</div>
              <button
                className='dropdown-item cropview-menu-text px-3'
                onClick={() => {
                  setSortMethod('username');
                }}
              >
                UserName {sortMethod == 'username' && <FontAwesomeIcon icon='fas fa-check' />}
              </button>
              <button
                className='dropdown-item cropview-menu-text px-3'
                onClick={() => {
                  setSortMethod('email');
                }}
              >
                Email {sortMethod == 'email' && <FontAwesomeIcon icon='fas fa-check' />}
              </button>
              <button
                className='dropdown-item cropview-menu-text px-3'
                onClick={() => {
                  setSortMethod('role');
                }}
              >
                Role {sortMethod == 'role' && <FontAwesomeIcon icon='fas fa-check' />}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function generateSettingsGroupTableHead() {
    return (
      <TableHead>
        <TableRow style={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}}>
          <TableCell colSpan={3}>
            <TableHeadFilterButtonTitleWrapper align='center' style={{textAlign: 'center'}} title={'Group Name'}>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#settings-group-name-select').toggle();
                }}
              >
                <FontAwesomeIcon
                  icon='fas fa-filter'
                  style={{color: filters['settingsGroupName'].length > 0 && '#4e73df'}}
                />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper align='center'>
              <Tailselect
                id='settings-group-name-select'
                name={'settingsGroupName'}
                multiple={true}
                search={true}
                value={filters['settingsGroupName']}
                options={filterOptions['settingsGroupName']}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function generateSettingsGroupTableBody() {
    return (
      <TableBody>
        {Object.keys(settingsGroups).map((groupId) => {
          let groupInFilter = false;
          if (filters.settingsGroupName.length == 0 || filters.settingsGroupName.includes(groupId)) {
            groupInFilter = true;
          }
          if (groupInFilter) {
            return (
              <TableRow key={groupId}>
                <TableCell width='100%'>{settingsGroups[groupId].name}</TableCell>
                <TableCell>
                  <button
                    className='btn btn-light'
                    style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                    onClick={() => {
                      handleCopyModalGroup(groupId);
                    }}
                  >
                    <FontAwesomeIcon icon='fa fa-copy' />
                  </button>
                </TableCell>
                <TableCell>
                  <button
                    className='btn btn-light'
                    style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                    onClick={() => {
                      handleSetModalGroup(groupId);
                    }}
                  >
                    <FontAwesomeIcon icon='fa fa-edit' />
                  </button>
                </TableCell>
              </TableRow>
            );
          }
        })}
      </TableBody>
    );
  }

  function generateTableHead() {
    return (
      <TableHead>
        <TableRow>
          {userSettings.roleAccess['teamManagement'] && (
            <TableCell>
              <Checkbox
                size='small'
                indeterminate={checkClickAllIndeterminate()}
                checked={checkClickAllChecked()}
                onClick={handleClickAll}
              />
            </TableCell>
          )}
          <TableCell>
            <TableHeadFilterButtonTitleWrapper title={'Username'}>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#user-manage-user-name-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters['userName'].length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='user-manage-user-name-select'
                name={'userName'}
                multiple={true}
                search={true}
                value={filters['userName']}
                options={filterOptions['userName']}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell sx={hideCell}>
            <TableHeadFilterButtonTitleWrapper title={'Email'}>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#user-manage-user-email-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters['userEmail'].length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='user-manage-user-email-select'
                name={'userEmail'}
                multiple={true}
                search={true}
                value={filters['userEmail']}
                options={filterOptions['userEmail']}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell sx={hideCell}>
            <TableHeadFilterButtonTitleWrapper title={'Settings Group(s)'}>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#user-manage-user-group-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters['group'].length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='user-manage-user-group-select'
                name={'group'}
                multiple={true}
                search={true}
                value={filters['group']}
                options={filterOptions['group']}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell sx={hideCell}>
            <TableHeadFilterButtonTitleWrapper title={'Role'}>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#user-manage-user-role-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters['role'].length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='user-manage-user-role-select'
                name={'role'}
                multiple={true}
                search={true}
                value={filters['role']}
                options={filterOptions['role']}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell sx={hideCell}>
            <TableHeadFilterButtonTitleWrapper title={'Status'}>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#user-manage-user-status-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters['status'].length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='user-manage-user-status-select'
                name={'status'}
                multiple={true}
                search={true}
                value={filters['status']}
                options={filterOptions['status']}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function generateTableBody() {
    let colorFlip = false;

    return loading ? (
      <TableRow>
        <TableCell colSpan={100}>
          <CircularProgress className='mt-4 mx-auto d-block' size={80} />
        </TableCell>
      </TableRow>
    ) : (
      <React.Fragment>
        {(rowsPerPage > 0 ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tableData).map(
          (user, idx) => {
            const userProfiles = [];
            if (
              user.hasOwnProperty('settingsGroupIds') &&
              user.hasOwnProperty('useSettingsGroup') &&
              user.useSettingsGroup !== false
            ) {
              user.settingsGroupIds.forEach((id) => {
                if (settingsGroups.hasOwnProperty(id)) {
                  userProfiles.push(settingsGroups[id].name);
                }
              });
            }
            colorFlip = !colorFlip;
            return (
              <TableRow sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}} key={user.username}>
                {userSettings.roleAccess['teamManagement'] && (
                  <TableCell>
                    <Checkbox
                      size='small'
                      onClick={(e) => {
                        handleClickUser(e, user.username);
                      }}
                      checked={selectedUsers.includes(user.username)}
                    />
                  </TableCell>
                )}
                <TableCell>{user.username}</TableCell>
                <TableCell sx={hideCell}>{user.email}</TableCell>
                <TableCell sx={hideCell}>
                  {userProfiles.map((profile, idx) => {
                    if (idx == userProfiles.length - 1) {
                      return profile;
                    }
                    return profile + ', ';
                  })}
                </TableCell>
                <TableCell sx={hideCell}>{roleValueToDisplayNameMapping[user.role]}</TableCell>
                <TableCell sx={hideCell}>
                  {user.archived ? <Chip label='Archived' color='error' /> : <Chip label='Active' color='success' />}
                </TableCell>
                <TableCell>
                  <button
                    className='btn btn-light'
                    style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                    onClick={() => {
                      setModalOpen(true);
                      setModalUser(user.username);
                    }}
                  >
                    <FontAwesomeIcon icon='fa fa-edit' />
                  </button>
                </TableCell>
              </TableRow>
            );
          }
        )}
      </React.Fragment>
    );
  }

  function generateTable() {
    return (
      <Table className='ic-mui-table' size='small' stickyHeader aria-label='simple table'>
        {generateTableHead()}
        <TableBody>{generateTableBody()}</TableBody>
      </Table>
    );
  }

  function generateSettingsGroupModal() {
    const tabs = [];
    const icons = [];

    if (userSettings.roleViewAccess['generalManagement']) {
      tabs.push('User Product');
      icons.push('fas fa-sliders');
    }

    const viewOnlyProfile = !userSettings.roleAccess['teamManagement'];
    const viewOnlyProduct = !userSettings.roleAccess['generalManagement'];

    if (modalGroup == null) {
      return submitting ? (
        settingGroupModalOpen ? (
          <ModalSubmitting />
        ) : null
      ) : (
        <Modal
          open={settingGroupModalOpen}
          onClose={() => {
            setSettingGroupModalOpen(false);
          }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='overflow-auto'
        >
          <Box sx={{...modalBoxStyle, overflow: 'hidden'}}>
            <TabMenuTableWrapper
              menu={
                <Box>
                  <Button
                    variant='ic-button'
                    color='success'
                    className='mx-1'
                    onClick={() => {
                      setModalGroup('newGroup');
                    }}
                  >
                    Create New Group
                  </Button>
                  <Box style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                    <h3>
                      <strong>Settings Groups</strong>
                    </h3>
                  </Box>
                </Box>
              }
              table={
                <Table align='center' size='small' stickyHeader sx={{'.MuiTableCell-root': {paddingX: 0.5}}}>
                  {generateSettingsGroupTableHead()}
                  {generateSettingsGroupTableBody()}
                </Table>
              }
              pagination={
                <Button
                  variant='ic-button'
                  color='secondary'
                  className='mx-1'
                  onClick={() => {
                    setSettingGroupModalOpen(false);
                  }}
                >
                  Exit
                </Button>
              }
            />
          </Box>
        </Modal>
      );
    } else if (modalGroup != null && groupForDelete == null) {
      return (
        <Modal
          open={settingGroupModalOpen}
          onClose={() => {
            setSettingGroupModalOpen(false);
          }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='overflow-auto'
        >
          <Box sx={modalBoxStyle}>
            {submitting ? (
              <ModalSubmitting />
            ) : (
              <React.Fragment>
                <div
                  style={{display: 'flex', flexDirection: 'rpw', textAlign: 'center', justifyContent: 'space-between'}}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      setModalGroup(null);
                      resetGroupModal();
                    }}
                  >
                    <FontAwesomeIcon icon='fas fa-arrow-left' />
                  </button>

                  {!viewOnlyProfile && !viewOnlyProduct && modalGroup != 'newGroup' && (
                    <Button
                      variant='ic-button'
                      color='danger'
                      className='mx-1'
                      onClick={() => {
                        setGroupForDelete(modalGroup);
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </div>
                <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                  <h3>
                    <strong>{`${modalGroup == 'newGroup' ? 'Create' : 'Edit'} Settings Group`}</strong>
                  </h3>

                  <Tabs tabs={tabs} icons={icons} />
                  <Table size='small' aria-label='simple table'>
                    <TableBody>
                      <SettingsText
                        label='Settings Group Name'
                        setting='name'
                        formValues={settingGroupModalFormValues}
                        handleChange={handleGroupModalChange}
                        disabled={viewOnlyProduct}
                      />
                    </TableBody>
                  </Table>
                  <TabContent
                    content={[
                      // Product Tab
                      <React.Fragment key={'User Product'}>
                        {viewOnlyProduct && <ViewOnlyText />}
                        <Table size='small' aria-label='simple table'>
                          <TableBody>
                            <SettingsSwitch
                              label='CabView Enabled'
                              setting='cabviewEnabled'
                              formValues={settingGroupModalFormValues}
                              handleChange={handleGroupModalChange}
                              disabled={viewOnlyProduct}
                            />
                            <SettingsSwitch
                              label='CropView Enabled'
                              setting='cropviewEnabled'
                              formValues={settingGroupModalFormValues}
                              handleChange={handleGroupModalChange}
                              disabled={viewOnlyProduct}
                            />
                            <SettingsSwitch
                              label='ShopView Enabled'
                              setting='shopviewEnabled'
                              formValues={settingGroupModalFormValues}
                              handleChange={handleGroupModalChange}
                              disabled={viewOnlyProduct}
                            />
                            <SettingsSwitch
                              label='Inspection Enabled'
                              setting='inspectionEnabled'
                              formValues={settingGroupModalFormValues}
                              handleChange={handleGroupModalChange}
                              disabled={viewOnlyProduct}
                            />
                            <SettingsSwitch
                              label='MapView Enabled'
                              setting='zoneInfoEnabled'
                              formValues={settingGroupModalFormValues}
                              handleChange={handleGroupModalChange}
                              disabled={viewOnlyProduct}
                            />
                            <SettingsSwitch
                              label='StatsView Enabled'
                              setting='dashboardEnabled'
                              formValues={settingGroupModalFormValues}
                              handleChange={handleGroupModalChange}
                              disabled={viewOnlyProduct}
                            />
                            <SettingsSwitch
                              label='AlertView Enabled'
                              setting='alertviewEnabled'
                              formValues={settingGroupModalFormValues}
                              handleChange={handleGroupModalChange}
                              disabled={viewOnlyProduct}
                            />
                            <SettingsSwitch
                              label='Global Filters Enabled'
                              setting='globalFiltersEnabled'
                              formValues={settingGroupModalFormValues}
                              handleChange={handleGroupModalChange}
                              disabled={viewOnlyProduct}
                            />
                            <SettingsSearchSelect
                              label='Included Vehicles'
                              setting='includedVehicles'
                              multiple
                              formValues={settingGroupModalFormValues}
                              handleChange={handleGroupModalChange}
                              disabled={viewOnlyProduct}
                              options={activeVehicles.map((veh) => {
                                return {
                                  value: veh.serialNumber,
                                  label: veh.name + (veh.archived ? ' (Archived)' : ''),
                                  group: machineTypeMapping[veh.machineType],
                                };
                              })}
                            />
                            <SettingsSearchSelect
                              label='Included Vehicle Labels'
                              setting='includedVehicleLabels'
                              multiple
                              formValues={settingGroupModalFormValues}
                              handleChange={handleGroupModalChange}
                              disabled={viewOnlyProduct}
                              options={Object.keys(vehicleLabelsDict).map((groupId) => {
                                return {
                                  value: groupId,
                                  label:
                                    vehicleLabelsDict[groupId].name +
                                    (vehicleLabelsDict[groupId].archived ? ' (Archived)' : ''),
                                };
                              })}
                            />
                            <SettingsSearchSelect
                              label='Included Blocks'
                              setting='includedBlocks'
                              multiple
                              formValues={settingGroupModalFormValues}
                              handleChange={handleGroupModalChange}
                              disabled={viewOnlyProduct}
                              options={Object.values(zonesDicts.blocks).map((zone) => {
                                return {value: zone.id, label: zone.name};
                              })}
                            />
                            <SettingsSearchSelect
                              label='Included Fields'
                              setting='includedFields'
                              multiple
                              formValues={settingGroupModalFormValues}
                              handleChange={handleGroupModalChange}
                              disabled={viewOnlyProduct}
                              options={Object.values(zonesDicts.fields).map((zone) => {
                                return {value: zone.id, label: zone.name};
                              })}
                            />
                            <SettingsSearchSelect
                              label='Included Regions'
                              setting='includedRegions'
                              multiple
                              formValues={settingGroupModalFormValues}
                              handleChange={handleGroupModalChange}
                              disabled={viewOnlyProduct}
                              options={Object.values(zonesDicts.regions).map((zone) => {
                                return {value: zone.id, label: zone.name};
                              })}
                            />
                          </TableBody>
                        </Table>
                      </React.Fragment>,
                    ]}
                  />
                  <div style={{color: 'red', textAlign: 'center'}} className='flex-row mt-2 col-12 px-1'>
                    {settingsGroupError}
                  </div>
                  <div className='flex-row my-3 col-12 px-1'>
                    <Button
                      variant='ic-button'
                      color='secondary'
                      className='mx-1'
                      onClick={() => {
                        setModalGroup(null);
                        resetGroupModal();
                      }}
                    >
                      Cancel
                    </Button>
                    {!viewOnlyProfile && !viewOnlyProduct && (
                      <Button variant='ic-button' color='primary' className='mx-1' onClick={handleGroupModalSubmit}>
                        Submit
                      </Button>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}
          </Box>
        </Modal>
      );
    } else if (groupForDelete != null && settingsGroups.hasOwnProperty(groupForDelete)) {
      return (
        <Modal
          open={settingGroupModalOpen}
          onClose={() => {
            setSettingGroupModalOpen(false);
          }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='overflow-auto'
        >
          <Box sx={modalBoxStyle}>
            {submitting ? (
              <ModalSubmitting />
            ) : (
              <React.Fragment>
                <button
                  className='btn btn-transparent btn-sm'
                  onClick={() => {
                    setGroupForDelete(null);
                  }}
                >
                  <FontAwesomeIcon icon='fas fa-arrow-left' />
                </button>
                <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                  <h3>
                    <strong>
                      Are you sure you wish to delete{' '}
                      {settingsGroups.hasOwnProperty(groupForDelete) ? settingsGroups[groupForDelete].name : ''}
                    </strong>
                  </h3>

                  <div style={{color: 'red', textAlign: 'center'}} className='flex-row mt-2 col-12 px-1'>
                    {settingsGroupError}
                  </div>
                  <div className='flex-row my-3 col-12 px-1'>
                    <Button
                      variant='ic-button'
                      color='secondary'
                      className='mx-1'
                      onClick={() => {
                        setGroupForDelete(null);
                      }}
                    >
                      Cancel
                    </Button>
                    {(!viewOnlyProfile || !viewOnlyProduct) && (
                      <Button variant='ic-button' color='danger' className='mx-1' onClick={handleDeleteGroup}>
                        Delete
                      </Button>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}
          </Box>
        </Modal>
      );
    }
  }

  return (
    <React.Fragment>
      {generateModal()}
      {generateSettingsGroupModal()}
      <TabMenuTableWrapper
        menu={generateMenu()}
        table={generateTable()}
        pagination={
          <CustomTablePagination
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
          />
        }
      />
      <BulkUploadModal
        entityName={'Users'}
        modalOpen={bulkUploadModalOpen}
        handleModalOpen={handleBulkUploadModalOpen}
        dataValidationAndMapping={validateAndMapBulkUploadedData}
        dataPreview={bulkUploadDataPreview}
        acceptedColumnHeaders={usersCsvColumns}
        bulkUploadSubmit={postBulkUsers}
        refreshData={refreshData}
      />
    </React.Fragment>
  );
}

export {UsersTab};
