import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {sendGAEvent, distanceBetweenPoints, fetchPostAuthSafe, formatString, deepCopy} from '../../app/utils';
import {DateTime, Interval, Duration} from 'luxon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {KpiEfficiency} from './Kpi/KpiEfficiency';
import {KpiIdling} from './Kpi/KpiIdling';
import {KpiInspectionRates} from './Kpi/KpiInspectionRates';
import {KpiMachineUsage} from './Kpi/KpiMachineUsage';
import {KpiMissingTasks} from './Kpi/KpiMissingTasks';
import {KpiOperationalEfficiency} from './Kpi/KpiOperationalEfficiency';
import {KpiServiceCosts} from './Kpi/KpiServiceCosts';
import {KpiServiceCompletion} from './Kpi/KpiServiceCompletion';
import {KpiTasksLogged} from './Kpi/KpiTasksLogged';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  FormControl,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Grid,
  Divider,
  ListItemIcon,
  Collapse,
  Modal,
  Box,
  Button,
  CircularProgress,
  TextField,
  Tooltip,
} from '@mui/material';

import {
  CreateNewScorecardModal,
  SimpleSnackbar,
  checkDateStringsSame,
  subsetToNameIconDict,
  KpiTooltip,
} from './dashboardUtils';

import {TabMenuTableWrapper} from '../../components/TabMenuTableWrapper';
import Pagination from '@mui/material/Pagination';
import {Menu} from './Menu';
import {CompareBy} from './CompareBy';
import {ExportButton} from './ExportButton';

import {
  setLoading,
  setDatesCache,
  setSelectedScorecardId,
  setSelectedScorecardPageIndex,
  setScorecards,
  setEditScorecardsIdDict,
  setSelectedKPI,
  setSelectedSubset,
  updateAddToScorecardTrigger,
  setTriggerRefreshData,
} from './dashboardSlice';

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

const scorecardContentCardStyle = {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  marginBottom: '10px',
  height: '100%',
  overflowY: 'auto',
  boxShadow: 'inset 2px 0px 12px 0px #888888',
  padding: 1.5,
};

const sideNavWrapperStyle = {
  marginLeft: 0,
  marginTop: 0,
  paddingBottom: '10px',
  display: 'block',
};

const dividerStyle = {
  width: '100%',
  borderColor: '#056905',
  borderWidth: '1px',
  overflow: 'hidden',
};

const sideNavSubheaderStyle = {
  // color: '#056905',
  fontWeight: 600,
  paddingX: '8px',
  paddingY: '4px',
  fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
};

// Declare abort controllers
let abortController;

function ScoreCardWrapper(props) {
  abortController = new AbortController();
  const dispatch = useDispatch();

  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const datesCache = useSelector((state) => {
    return state.dashboard.dates;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const inspectionsData = useSelector((state) => {
    return state.dashboard.inspectionsData;
  });
  const servicesData = useSelector((state) => {
    return state.dashboard.servicesData;
  });
  const compareByToggle = useSelector((state) => {
    return state.dashboard.compareByToggle;
  });
  const comparisonLoading = useSelector((state) => {
    return state.dashboard.comparisonLoading;
  });
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const inspectionsDict = useSelector((state) => {
    return state.dashboard.inspectionsDict;
  });
  const kpiLandingPageOn = useSelector((state) => {
    return state.dashboard.kpiLandingPageOn;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const selectedKPI = useSelector((state) => {
    return state.dashboard.selectedKPI;
  });
  const scorecards = useSelector((state) => {
    return state.dashboard.scorecards;
  });
  const scorecardsIdDict = useSelector((state) => {
    return state.dashboard.scorecardsIdDict;
  });
  const editScorecardsIdDict = useSelector((state) => {
    return state.dashboard.editScorecardsIdDict;
  });
  const selectedScorecardId = useSelector((state) => {
    return state.dashboard.selectedScorecardId;
  });
  const selectedScorecardPageIndex = useSelector((state) => {
    return state.dashboard.selectedScorecardPageIndex;
  });
  const displaySettings = useSelector((state) => {
    return state.framework;
  });
  const selectedSubset = useSelector((state) => {
    return state.dashboard.selectedSubset;
  });
  const triggerRefreshData = useSelector((state) => {
    return state.dashboard.triggerRefreshData;
  });

  const keyRef = useRef(DateTime.now());
  const [searchParams, setSearchParams] = useSearchParams();
  const [distanceUnits, setDistanceUnits] = useState('km');
  const [speedUnits, setSpeedUnits] = useState('kph');
  const [editMode, setEditMode] = useState(false);
  const [editModeErrorMessage, setEditModeErrorMessage] = useState(null);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [copiedOpen, setCopiedOpen] = useState(false);
  const [smallscreenSidebarOpen, setSmallscreenSidebarOpen] = useState(false);

  const [farmingTabs, setFarmingTabs] = useState([]);
  const [machineryTabs, setMachineryTabs] = useState([]);

  const [reportListOpen, setReportListOpen] = useState(selectedKPI != 'Scorecards');
  const [scorecardListOpen, setScorecardListOpen] = useState(selectedKPI == 'Scorecards');
  const [farmingListOpen, setFarmingListOpen] = useState(selectedKPI == 'Farming');
  const [machineryListOpen, setMachineryListOpen] = useState(selectedKPI == 'Machinery');

  const scorecardEditAccess = userSettings.roleAccess['dashboardScorecardManagement'];

  useEffect(() => {
    dispatch(setTriggerRefreshData(false));
  }, [datesCache]);

  useEffect(() => {
    if (triggerRefreshData) {
      refreshOperationsData();
    }
  }, [triggerRefreshData]);

  useEffect(() => {
    let distUnits = 'km';
    if (unitsSystem == 'imperial') {
      distUnits = 'mi';
    }
    setDistanceUnits(distUnits);

    let spdUnits = 'kph';
    if (unitsSystem == 'imperial') {
      spdUnits = 'mph';
    }
    setSpeedUnits(spdUnits);
  }, [unitsSystem]);

  useEffect(() => {
    if (selectedKPI == 'Scorecards') {
      setScorecardListOpen(true);
    }
  }, [selectedKPI]);

  useEffect(() => {
    // Select ScoreCard Effect
    if (selectedScorecardId != null && scorecardsIdDict.hasOwnProperty(selectedScorecardId)) {
      // Update Date Range fetched
      const startISO = scorecardsIdDict[selectedScorecardId].startDate['@ts'].toString();
      const endISO = scorecardsIdDict[selectedScorecardId].endDate['@ts'].toString();

      // Format dates the same way to check if data retrieval is necessary
      const startTimeFormattedCache = DateTime.fromISO(datesCache.start).toUTC().toISO();
      const endTimeFormattedCache = DateTime.fromISO(datesCache.end).toUTC().toISO();
      const startTimeFormattedScorecard = DateTime.fromISO(startISO).toUTC().toISO();
      const endTimeFormattedScorecard = DateTime.fromISO(endISO).toUTC().toISO();

      if (
        startTimeFormattedScorecard != startTimeFormattedCache ||
        endTimeFormattedScorecard != endTimeFormattedCache
      ) {
        dispatch(setDatesCache({start: startTimeFormattedScorecard, end: endTimeFormattedScorecard}));
      }
      // Update some UI.
      if (scorecardsIdDict[selectedScorecardId].pages.length > 0) {
        dispatch(setSelectedScorecardPageIndex(0));
      } else {
        dispatch(setSelectedScorecardPageIndex(null));
      }
    }
  }, [selectedScorecardId, scorecardsIdDict]);

  useEffect(async () => {
    determineTabDisplay();
  }, [customerSettings]);

  async function refreshOperationsData() {
    const queryData = {
      start: datesCache.start,
      end: datesCache.end,
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      cache: 'no-store',
      body: JSON.stringify(queryData),
    };

    const response = await fetchPostAuthSafe(
      `/statsview/refreshOperationsData`,
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const result = await response.json();

    dispatch(setTriggerRefreshData(false));
  }

  function determineTabDisplay() {
    const farmingTabs = [];
    const machineryTabs = [];

    // Farming
    if (customerSettings.dashboard.kpiEfficiencyEnabled) {
      farmingTabs.push({
        subset: 'Efficiency',
        ...subsetToNameIconDict['Efficiency'],
      });
    }
    if (customerSettings.dashboard.kpiOperationalEfficiencyEnabled) {
      farmingTabs.push({
        subset: 'Operational Efficiency',
        ...subsetToNameIconDict['Operational Efficiency'],
      });
    }
    if (customerSettings.dashboard.kpiTasksLoggedEnabled) {
      farmingTabs.push({
        subset: 'Tasks Logged',
        ...subsetToNameIconDict['Tasks Logged'],
      });
    }
    if (customerSettings.dashboard.kpiMissingTasksEnabled) {
      farmingTabs.push({
        subset: 'Missing Tasks',
        ...subsetToNameIconDict['Missing Tasks'],
      });
    }

    // Machinery
    if (customerSettings.dashboard.kpiMachineUsageEnabled) {
      machineryTabs.push({
        subset: 'Machine Usage',
        ...subsetToNameIconDict['Machine Usage'],
      });
    }
    if (customerSettings.dashboard.kpiIdlingEnabled) {
      machineryTabs.push({
        subset: 'Idling',
        ...subsetToNameIconDict['Idling'],
      });
    }
    if (customerSettings.dashboard.kpiServiceCostsEnabled) {
      machineryTabs.push({
        subset: 'Service Costs',
        ...subsetToNameIconDict['Service Costs'],
      });
    }
    if (customerSettings.dashboard.kpiServiceCompletionEnabled) {
      machineryTabs.push({
        subset: 'Service Health',
        ...subsetToNameIconDict['Service Health'],
      });
    }
    if (customerSettings.dashboard.kpiInspectionRatesEnabled) {
      machineryTabs.push({
        subset: 'Inspection Rates',
        ...subsetToNameIconDict['Inspection Rates'],
      });
    }

    setFarmingTabs(farmingTabs);
    setMachineryTabs(machineryTabs);
  }

  function handleOpenList(listType) {
    switch (listType) {
      case 'report':
        setReportListOpen(!reportListOpen);
        break;
      case 'farming':
        setFarmingListOpen(!farmingListOpen);
        break;
      case 'machinery':
        setMachineryListOpen(!machineryListOpen);
        break;
      default:
        break;
    }
  }

  function handleScorecardNameEditChange(e) {
    const scorecardsIdDictCopy = deepCopy(editScorecardsIdDict);
    scorecardsIdDictCopy[selectedScorecardId].name = e.target.value;

    dispatch(setEditScorecardsIdDict(scorecardsIdDictCopy));
  }

  async function handleSetEditMode(save = false) {
    if (!scorecardEditAccess) {
      return null;
    }
    // Default save validated to true, return false from handleSaveEdits if data validation fails
    let saveValidated = true;
    if (save) {
      dispatch(setLoading(true));
      saveValidated = await handleSaveEdits();
      dispatch(setLoading(false));
    }
    if (editMode) {
      const originalScorecardStartDate = scorecardsIdDict[selectedScorecardId].startDate['@ts'];
      const newScorecardStartDate = editScorecardsIdDict[selectedScorecardId].startDate['@ts'];

      const originalScorecardEndDate = scorecardsIdDict[selectedScorecardId].endDate['@ts'];
      const newScorecardEndDate = editScorecardsIdDict[selectedScorecardId].endDate['@ts'];

      // If we are saving we check the new dates
      const newDatesMatchCache =
        checkDateStringsSame(newScorecardStartDate, datesCache.start) &&
        checkDateStringsSame(newScorecardEndDate, datesCache.end);
      // If we are not saving we check the old dates
      const oldDatesMatchCache =
        checkDateStringsSame(originalScorecardStartDate, datesCache.start) &&
        checkDateStringsSame(originalScorecardEndDate, datesCache.end);

      if (save && !newDatesMatchCache) {
        dispatch(setDatesCache({start: newScorecardStartDate, end: newScorecardEndDate}));
      } else if (!save && !oldDatesMatchCache) {
        dispatch(setDatesCache({start: originalScorecardStartDate, end: originalScorecardEndDate}));
      }
    }
    if (saveValidated) {
      // reset states when changes are saved
      setEditMode(!editMode);
      dispatch(setEditScorecardsIdDict(scorecardsIdDict));
      setEditModeErrorMessage(null);
    }
  }

  function datePickerRender() {
    let buttonDisplayString = '';
    const selectedScorecardDates = {
      start: DateTime.fromISO(editScorecardsIdDict[selectedScorecardId].startDate['@ts']).setZone(
        customerSettings.general.timeZone,
        {}
      ),
      end: DateTime.fromISO(editScorecardsIdDict[selectedScorecardId].endDate['@ts']).setZone(
        customerSettings.general.timeZone,
        {}
      ),
    };

    if (
      typeof selectedScorecardDates.start !== 'undefined' &&
      typeof selectedScorecardDates.end !== 'undefined' &&
      selectedScorecardDates.start != null &&
      selectedScorecardDates.end != null
    ) {
      buttonDisplayString =
        ` ${selectedScorecardDates.start.toFormat('L/d/yy')} ` + `- ${selectedScorecardDates.end.toFormat('L/d/yy')} `;
      return (
        <DateRangePicker
          key={keyRef.current}
          onApply={editDateSelection}
          initialSettings={{
            startDate: selectedScorecardDates.start.toFormat('MM/dd/yyyy'),
            endDate: selectedScorecardDates.end.toFormat('MM/dd/yyyy'),
            // timePicker: false,
            // timePicker24Hour: false,
            locale: {
              format: 'MM/DD/YYYY',
            },
          }}
          disabled={loading}
        >
          <button className='btn border-dark btn-light col-12 cropview-menu-text h-100'>
            <i className='fas fa-calendar-alt' />
            {buttonDisplayString}
          </button>
        </DateRangePicker>
      );
    }
  }

  function editDateSelection(event, picker) {
    const startDate = DateTime.fromISO(picker.startDate.toISOString())
      .setZone(customerSettings.general.timeZone, {
        keepLocalTime: true,
      })
      .toISO();
    const endDate = DateTime.fromISO(picker.endDate.toISOString())
      .set({
        second: 59,
        millisecond: 999,
      })
      .setZone(customerSettings.general.timeZone, {keepLocalTime: true})
      .toISO();

    const scorecardsIdDictCopy = deepCopy(editScorecardsIdDict);
    scorecardsIdDictCopy[selectedScorecardId].startDate = {'@ts': startDate};
    scorecardsIdDictCopy[selectedScorecardId].endDate = {'@ts': endDate};
    dispatch(setEditScorecardsIdDict(scorecardsIdDictCopy));
    dispatch(setDatesCache({start: startDate, end: endDate}));
  }

  async function handleSaveEdits() {
    const updateObject = {
      id: selectedScorecardId,
      pages: [],
    };
    const originalScorecardName = scorecardsIdDict[selectedScorecardId].name;
    const newScorecardName = editScorecardsIdDict[selectedScorecardId].name;
    if (originalScorecardName != newScorecardName) {
      updateObject.name = newScorecardName;
    }
    const originalScorecardStartDate = scorecardsIdDict[selectedScorecardId].startDate['@ts'];
    const newScorecardStartDate = editScorecardsIdDict[selectedScorecardId].startDate['@ts'];

    if (!checkDateStringsSame(originalScorecardStartDate, newScorecardStartDate)) {
      updateObject.startDate = newScorecardStartDate;
    }

    const originalScorecardEndDate = scorecardsIdDict[selectedScorecardId].endDate['@ts'];
    const newScorecardEndDate = editScorecardsIdDict[selectedScorecardId].endDate['@ts'];

    if (!checkDateStringsSame(originalScorecardEndDate, newScorecardEndDate)) {
      updateObject.endDate = newScorecardEndDate;
    }

    const pageNamesArray = [];
    let duplicatePageNames = false;

    for (let i = 0; i < editScorecardsIdDict[selectedScorecardId].pages.length; i++) {
      const originalPageIndex = editScorecardsIdDict[selectedScorecardId].pages[i].originalPageIndex;
      const originalPageName = scorecardsIdDict[selectedScorecardId].pages[originalPageIndex].pageName;
      const originalPageNotes = scorecardsIdDict[selectedScorecardId].pages[originalPageIndex]?.annotation;
      const newPageIndex = editScorecardsIdDict[selectedScorecardId].pages[i].newPageIndex;
      const newPageName = editScorecardsIdDict[selectedScorecardId].pages[i].pageName;
      const newPageNotes = editScorecardsIdDict[selectedScorecardId].pages[i]?.annotation;
      const deletedPage = editScorecardsIdDict[selectedScorecardId].pages[i].deleted;
      const pageUpdateObject = {};
      if (newPageIndex != originalPageIndex) {
        pageUpdateObject.newPageIndex = newPageIndex;
        pageUpdateObject.originalPageIndex = originalPageIndex;
        pageUpdateObject.originalPageName = originalPageName;
      }
      if (originalPageName != newPageName) {
        pageUpdateObject.pageName = newPageName;
        pageUpdateObject.originalPageName = originalPageName;
      }
      if (originalPageNotes != newPageNotes) {
        pageUpdateObject.annotation = newPageNotes;
        pageUpdateObject.originalPageName = originalPageName;
      }
      if (deletedPage) {
        pageUpdateObject.deleted = true;
        pageUpdateObject.originalPageName = originalPageName;
        pageUpdateObject.pageName = originalPageName;
      }
      if (!deletedPage) {
        if (pageNamesArray.includes(newPageName)) {
          duplicatePageNames = true;
        } else {
          pageNamesArray.push(newPageName);
        }
      }
      if (Object.keys(pageUpdateObject).length > 0) {
        updateObject.pages.push(pageUpdateObject);
      }
    }

    if (duplicatePageNames) {
      // Validate no duplicate page Names before submission
      setEditModeErrorMessage('Scorecard pages cannot have the same names. Please correct the changes and try again.');
      return false;
    }

    if (Object.keys(updateObject).length > 2 || updateObject.pages.length > 0) {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        cache: 'no-store',
        body: JSON.stringify(updateObject),
      };
      const response = await fetchPostAuthSafe(
        '/statsview/updateScorecard',
        options,
        userSettings.username,
        userSettings.databaseName
      );

      const result = await response.json();

      dispatch(setScorecards(result.data));
    }
    return true;
  }

  function handleConfirmDeleteScorecard() {
    setConfirmDeleteModalOpen(true);
  }

  function renderDeleteConfirmModal() {
    const handleClose = () => {
      setConfirmDeleteModalOpen(false);
    };
    if (scorecardsIdDict.hasOwnProperty(selectedScorecardId)) {
      return (
        <div>
          <Modal
            open={confirmDeleteModalOpen}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={boxStyle}>
              <div id='modal-modal-title'>
                Are you sure you wish to delete Scorecard -{' '}
                <strong>{scorecardsIdDict[selectedScorecardId].name}</strong>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                <Button
                  variant='ic-button'
                  color='secondary'
                  onClick={() => {
                    setConfirmDeleteModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  sx={{backgroundColor: 'red', color: 'white'}}
                  color='secondary'
                  variant='ic-button'
                  onClick={handleDeleteScorecard}
                >
                  Delete
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
      );
    }
  }

  async function handleDeleteScorecard() {
    setConfirmDeleteModalOpen(false);
    dispatch(setLoading(true));
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      cache: 'no-store',
      body: JSON.stringify({id: selectedScorecardId}),
    };
    const response = await fetchPostAuthSafe(
      '/statsview/deleteScorecard',
      options,
      userSettings.username,
      userSettings.databaseName
    );

    const result = await response.json();
    dispatch(setSelectedScorecardId(null));
    dispatch(setScorecards(result.data));
    setEditMode(false);
    dispatch(setLoading(false));
  }

  function handleReorderPage(index, direction) {
    const scorecardsIdDictCopy = deepCopy(editScorecardsIdDict);
    const previousPageAtNewIndex = deepCopy(scorecardsIdDictCopy[selectedScorecardId].pages[index + direction]);
    const previousPageAtCurrentIndex = deepCopy(scorecardsIdDictCopy[selectedScorecardId].pages[index]);

    scorecardsIdDictCopy[selectedScorecardId].pages[index] = previousPageAtNewIndex;
    scorecardsIdDictCopy[selectedScorecardId].pages[index + direction] = previousPageAtCurrentIndex;
    scorecardsIdDictCopy[selectedScorecardId].pages.forEach((page, pageIndex) => {
      page.newPageIndex = pageIndex;
    });
    dispatch(setEditScorecardsIdDict(scorecardsIdDictCopy));
    if (selectedScorecardPageIndex == index + direction && direction > 0) {
      // Selected Page move up and Current Page moved down
      dispatch(setSelectedScorecardPageIndex(selectedScorecardPageIndex - 1));
    } else if (selectedScorecardPageIndex == index + direction && direction < 0) {
      // Selected Page move down and Current Page moved up
      dispatch(setSelectedScorecardPageIndex(selectedScorecardPageIndex + 1));
    } else if (selectedScorecardPageIndex == index) {
      // Selected Page is Current Page
      dispatch(setSelectedScorecardPageIndex(selectedScorecardPageIndex + direction));
    }
  }

  function handleDeletePage(index, deletePage) {
    const scorecardsIdDictCopy = deepCopy(editScorecardsIdDict);
    scorecardsIdDictCopy[selectedScorecardId].pages[index].deleted = deletePage;
    dispatch(setEditScorecardsIdDict(scorecardsIdDictCopy));
  }

  function handleScorecardPageNameEditChange(index, newPageName) {
    const scorecardsIdDictCopy = deepCopy(editScorecardsIdDict);
    scorecardsIdDictCopy[selectedScorecardId].pages[index].pageName = newPageName;
    dispatch(setEditScorecardsIdDict(scorecardsIdDictCopy));
  }

  function handleScorecardPageNotesEditChange(index, newAnnotation) {
    const scorecardsIdDictCopy = deepCopy(editScorecardsIdDict);
    scorecardsIdDictCopy[selectedScorecardId].pages[index].annotation = newAnnotation;
    dispatch(setEditScorecardsIdDict(scorecardsIdDictCopy));
  }

  function handleCreateLink() {
    const shareableLink = window.location.origin + '/statsview?scorecardId=' + selectedScorecardId;
    navigator.clipboard.writeText(shareableLink);
    setCopiedOpen(true);
  }

  function scorecardHeader() {
    return (
      <React.Fragment>
        {editModeErrorMessage != null && (
          <div className='text-danger font-weight-bold font-italic'>{editModeErrorMessage}</div>
        )}
        {selectedScorecardPageIndex == null && selectedScorecardId != null && (
          <div> No Page Available in this scorecard</div>
        )}
        {selectedScorecardPageIndex == null && selectedScorecardId == null && <div> Please Select a Scorecard</div>}
        {selectedScorecardPageIndex != null && selectedScorecardId != null && !editMode && (
          <div className='px-2'>
            <h3 className='font-weight-bold' style={{color: '#056905'}}>
              {scorecardsIdDict[selectedScorecardId].name}
            </h3>
            <h5 style={{color: '#056905'}}>
              <span className='font-italic'>{`${DateTime.fromISO(scorecardsIdDict[selectedScorecardId].startDate['@ts'])
                .setZone(customerSettings.general.timeZone, {})
                .toISO()
                .slice(0, 10)} - ${DateTime.fromISO(scorecardsIdDict[selectedScorecardId].endDate['@ts'])
                .setZone(customerSettings.general.timeZone, {})
                .toISO()
                .slice(0, 10)}`}</span>
              <span>
                {' '}
                | <strong>{scorecardsIdDict[selectedScorecardId].pages[selectedScorecardPageIndex]?.pageName}</strong>
              </span>
            </h5>

            <div>
              {compareByToggle &&
                scorecardsIdDict[selectedScorecardId].pages[selectedScorecardPageIndex]?.pageStates.hasOwnProperty(
                  'compareByDateRange'
                ) &&
                (!comparisonLoading ? (
                  <React.Fragment>
                    <span>
                      {' '}
                      <strong>Compared with: </strong>
                    </span>
                    <span className='font-italic'>{`${DateTime.fromISO(
                      scorecardsIdDict[selectedScorecardId].pages[selectedScorecardPageIndex]?.pageStates
                        ?.compareByDateRange?.start
                    )
                      .setZone(customerSettings.general.timeZone, {})
                      .toISO()
                      .slice(0, 10)} - ${DateTime.fromISO(
                      scorecardsIdDict[selectedScorecardId].pages[selectedScorecardPageIndex]?.pageStates
                        ?.compareByDateRange.end
                    )
                      .setZone(customerSettings.general.timeZone, {})
                      .toISO()
                      .slice(0, 10)}`}</span>
                  </React.Fragment>
                ) : (
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div>Loading Comparison Data...</div>
                    <CircularProgress style={{'marginLeft': '10px', 'marginRight': '10px'}} size={30} />
                  </div>
                ))}
            </div>

            {scorecardsIdDict[selectedScorecardId].pages[selectedScorecardPageIndex]?.annotation &&
              scorecardsIdDict[selectedScorecardId].pages[selectedScorecardPageIndex]?.annotation != '' && (
                <React.Fragment>
                  <div className='font-weight-bold' style={{fontSize: '20px'}}>
                    Notes:
                  </div>
                  <div style={{whiteSpace: 'pre-wrap'}}>
                    {scorecardsIdDict[selectedScorecardId].pages[selectedScorecardPageIndex]?.annotation}
                  </div>
                </React.Fragment>
              )}
          </div>
        )}
        {selectedScorecardPageIndex != null && selectedScorecardId != null && editMode && (
          <React.Fragment>
            <div className='row m-0'>
              <h3 className='col' style={{marginTop: '10px'}}>
                {editScorecardsIdDict[selectedScorecardId].pages[selectedScorecardPageIndex]?.pageName}
              </h3>
            </div>
            <div className='row col-12 col-md-8 mx-0 py-2 px-0'>
              <TextField
                label='Notes'
                size='small'
                fullWidth
                multiline
                rows={3}
                onChange={(e) => {
                  handleScorecardPageNotesEditChange(selectedScorecardPageIndex, e.target.value);
                }}
                value={
                  editScorecardsIdDict[selectedScorecardId].pages[selectedScorecardPageIndex]?.annotation != undefined
                    ? editScorecardsIdDict[selectedScorecardId].pages[selectedScorecardPageIndex]?.annotation
                    : ''
                }
              />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  function scorecardBody() {
    return (
      <React.Fragment>
        <div className='row p-0 m-0 justify-content-center'>
          {displayScoreCardKPI()}
          {renderDeleteConfirmModal()}
        </div>
      </React.Fragment>
    );
  }

  function scorecardPagination() {
    return (
      <Pagination
        color='primary'
        shape='rounded'
        count={
          editScorecardsIdDict.hasOwnProperty(selectedScorecardId) &&
          editScorecardsIdDict[selectedScorecardId].hasOwnProperty('pages')
            ? editScorecardsIdDict[selectedScorecardId].pages.length
            : 0
        }
        sx={{
          '.MuiPagination-ul': {
            justifyContent: 'space-evenly',
          },
          '.MuiPaginationItem-firstLast': {
            paddingLeft: '0px',
            paddingRight: '0px',
          },
          '.MuiPaginationItem-page.Mui-selected': {
            backgroundColor: '#056905!important',
          },
          width: '100%',
        }}
        onChange={(event, value) => {
          if (editScorecardsIdDict[selectedScorecardId].pages.length >= value) {
            dispatch(setSelectedScorecardPageIndex(value - 1));
          }
          // Remove 'focus' border on element on change to prevent border latching
          event.target.blur();
        }}
        page={selectedScorecardPageIndex + 1}
        siblingCount={displaySettings.smallScreen ? 1 : 3}
        size={displaySettings.smallScreen ? 'small' : 'medium'}
        showFirstButton
        showLastButton
        hidePrevButton={displaySettings.smallScreen}
        hideNextButton={displaySettings.smallScreen}
      />
    );
  }

  function scorecardControlButtons() {
    return (
      <div className='row col-12 p-0 m-0'>
        {!editMode && !loading && (
          <React.Fragment>
            {scorecardEditAccess && (
              <React.Fragment>
                <button
                  className='btn border-dark btn-light col-3 cropview-menu-text'
                  onClick={() => {
                    handleSetEditMode();
                  }}
                  disabled={!Object.hasOwnProperty.call(scorecardsIdDict, selectedScorecardId)}
                >
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25px'}}>
                    <FontAwesomeIcon
                      style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '18px'}}
                      icon='fas fa-edit'
                    />
                  </div>
                </button>
                <CreateNewScorecardModal />
              </React.Fragment>
            )}
            {selectedScorecardId != null && (
              <Tooltip title='Create Link To This Scorecard'>
                <button
                  className='btn border-dark btn-light col-3 cropview-menu-text'
                  onClick={() => {
                    handleCreateLink();
                  }}
                >
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25px'}}>
                    <FontAwesomeIcon
                      style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '18px'}}
                      icon='fas fa-link'
                    />
                  </div>
                </button>
              </Tooltip>
            )}
            <SimpleSnackbar open={copiedOpen} setOpen={setCopiedOpen} />
          </React.Fragment>
        )}
        {scorecardEditAccess && editMode && !loading && (
          <React.Fragment>
            <button
              className='btn border-dark btn-light col-3 cropview-menu-text'
              onClick={() => {
                handleSetEditMode(true);
              }}
            >
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25px'}}>
                <FontAwesomeIcon
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '18px',
                    color: 'green',
                  }}
                  icon='fas fa-save'
                />
              </div>
            </button>
            <button
              className='btn border-dark btn-light col-3 cropview-menu-text'
              onClick={() => {
                handleSetEditMode();
              }}
            >
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25px'}}>
                <FontAwesomeIcon
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '18px',
                    color: 'red',
                  }}
                  icon='fas fa-ban'
                />
              </div>
            </button>
            <button
              className='btn border-dark btn-light col-3 cropview-menu-text'
              onClick={() => {
                handleConfirmDeleteScorecard();
              }}
            >
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25px'}}>
                <FontAwesomeIcon
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '18px',
                    color: 'black',
                  }}
                  icon='fas fa-trash'
                />
              </div>
            </button>
          </React.Fragment>
        )}
        {displaySettings.smallScreen && !loading && (
          <button
            className='btn border-dark btn-light col-3 cropview-menu-text'
            onClick={() => {
              setSmallscreenSidebarOpen(true);
            }}
          >
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25px'}}>
              <FontAwesomeIcon
                style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '18px'}}
                icon='fas fa-list'
              />
            </div>
          </button>
        )}
      </div>
    );
  }

  function scorecardSelector() {
    return (
      <Select
        labelId='demo-simple-select-standard-label'
        id='demo-simple-select-standard'
        size='small'
        value={selectedScorecardId != null ? selectedScorecardId : 'placeholder'}
        label='Select Scorecard'
        MenuProps={{sx: {width: '100%', maxWidth: '200px'}}}
        onChange={(e) => {
          const scorecardId = e.target.value;
          dispatch(setSelectedKPI('Scorecards'));
          dispatch(setSelectedScorecardId(scorecardId));
        }}
      >
        <MenuItem key={'placeholder'} value={'placeholder'} hidden disabled>
          Please Select a Scorecard
        </MenuItem>
        {Object.keys(scorecardsIdDict).map((scorecardId) => {
          return (
            <MenuItem
              key={scorecardId}
              value={scorecardId}
              sx={{
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
              {scorecardsIdDict[scorecardId].name}
            </MenuItem>
          );
        })}
      </Select>
    );
  }

  function scorecardSideBarPageList() {
    return (
      <List sx={{width: '100%', bgcolor: 'background.paper'}}>
        {selectedScorecardId != null &&
          Object.hasOwnProperty.call(scorecardsIdDict, selectedScorecardId) &&
          pagesSideBarList.map((page, index) => {
            return (
              <ListItem
                key={index}
                disablePadding
                style={{
                  backgroundColor: selectedScorecardPageIndex == index && !editMode ? 'lightgrey' : '',
                  color: selectedScorecardPageIndex == index && !editMode ? '#056905' : 'black',
                }}
                secondaryAction={!editMode && <strong> {index + 1} </strong>}
              >
                {!editMode ? (
                  <ListItemButton
                    role={undefined}
                    dense
                    onClick={() => {
                      dispatch(setSelectedKPI('Scorecards'));
                      dispatch(
                        setSelectedSubset(scorecardsIdDict[selectedScorecardId]['pages'][index]?.selectedSubset)
                      );
                      dispatch(setSelectedScorecardPageIndex(index));
                    }}
                  >
                    <ListItemText id={index + 1} primary={page.pageName} />
                  </ListItemButton>
                ) : (
                  <React.Fragment>
                    {!editScorecardsIdDict[selectedScorecardId].pages[index].deleted ? (
                      <TextField
                        label='Change Page Name'
                        size='small'
                        multiline
                        onFocus={() => {
                          dispatch(setSelectedKPI('Scorecards'));
                          dispatch(setSelectedScorecardPageIndex(index));
                        }}
                        sx={{marginTop: '10px', width: '100%'}}
                        onChange={(e) => {
                          handleScorecardPageNameEditChange(index, e.target.value);
                        }}
                        value={editScorecardsIdDict[selectedScorecardId].pages[index].pageName}
                      />
                    ) : (
                      <ListItemText sx={{textDecoration: 'line-through'}} id={index + 1} primary={page.pageName} />
                    )}
                    <React.Fragment>
                      <FontAwesomeIcon
                        className='hoverPls d-flex justify-content-center align-items-center px-1'
                        onClick={() => {
                          handleDeletePage(index, !editScorecardsIdDict[selectedScorecardId].pages[index].deleted);
                        }}
                        style={{height: '18px'}}
                        icon={`fas ${
                          !editScorecardsIdDict[selectedScorecardId].pages[index].deleted
                            ? 'fa-trash'
                            : 'fa-trash-restore'
                        }`}
                      />
                      <div className='d-flex flex-column pr-1'>
                        {index != 0 && (
                          <FontAwesomeIcon
                            className='hoverPls d-flex justify-content-center align-items-center p-1'
                            onClick={() => {
                              handleReorderPage(index, -1);
                            }}
                            style={{height: '18px'}}
                            icon='fas fa-arrow-up'
                          />
                        )}
                        {editScorecardsIdDict[selectedScorecardId].pages.length - 1 != index && (
                          <FontAwesomeIcon
                            className='hoverPls d-flex justify-content-center align-items-center p-1'
                            onClick={() => {
                              handleReorderPage(index, 1);
                            }}
                            style={{height: '18px'}}
                            icon='fas fa-arrow-down'
                          />
                        )}
                      </div>
                    </React.Fragment>
                  </React.Fragment>
                )}
              </ListItem>
            );
          })}
        {editMode && (
          <ListItem disablePadding>
            <ListItemButton
              role={undefined}
              dense
              onClick={() => {
                setReportListOpen(true);
                setFarmingListOpen(true);
                props.scorecardNavigateToKPI();
              }}
            >
              <ListItemText
                primary={
                  <div className='d-flex'>
                    <FontAwesomeIcon
                      className='hoverPls'
                      style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '18px'}}
                      icon='fas fa-plus'
                    />
                    Add Page
                  </div>
                }
              />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    );
  }

  function farmingReportsNav() {
    const farmingSelected = selectedKPI == 'Farming';
    return farmingTabs.length > 0 ? (
      <React.Fragment>
        <ListItem dense sx={{pl: 2, pr: 1, backgroundColor: 'transparent !important'}}>
          <ListItemText secondary='Farming'></ListItemText>
        </ListItem>
        <List disablePadding>
          {farmingTabs.map((tabObj) => {
            return (
              <ListItemButton
                key={tabObj.subset}
                dense
                sx={{pl: 3}}
                selected={farmingSelected && selectedSubset == tabObj.subset}
                onClick={() => {
                  dispatch(setSelectedKPI('Farming'));
                  dispatch(setSelectedSubset(tabObj.subset));
                  dispatch(setSelectedScorecardPageIndex(null));
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={tabObj.icon} />
                </ListItemIcon>
                <ListItemText primary={tabObj.name} />
              </ListItemButton>
            );
          })}
        </List>
      </React.Fragment>
    ) : null;
  }

  function machineryReportsNav() {
    const machinerySelected = selectedKPI == 'Machinery';
    return machineryTabs.length > 0 ? (
      <React.Fragment>
        <ListItem dense sx={{pl: 2, pr: 1, backgroundColor: 'transparent !important'}}>
          <ListItemText secondary='Machinery' />
        </ListItem>
        <List disablePadding>
          {machineryTabs.map((tabObj) => {
            return (
              <ListItemButton
                key={tabObj.subset}
                dense
                sx={{pl: 3}}
                selected={machinerySelected && selectedSubset == tabObj.subset}
                onClick={() => {
                  dispatch(setSelectedKPI('Machinery'));
                  dispatch(setSelectedSubset(tabObj.subset));
                  dispatch(setSelectedScorecardPageIndex(null));
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={tabObj.icon} />
                </ListItemIcon>
                <ListItemText primary={tabObj.name} />
              </ListItemButton>
            );
          })}
        </List>
      </React.Fragment>
    ) : null;
  }

  function scorecardContent() {
    return (
      <React.Fragment>
        {loading && (
          <div className='row p-3'>
            <div className='col-12 text-center'>
              <CircularProgress />
            </div>
            <div className='col-12 text-center'>Loading Scorecard Data...</div>
          </div>
        )}
        {!loading && (
          <div className='w-100 h-100 p-3'>
            <TabMenuTableWrapper menu={scorecardHeader()} table={scorecardBody()} pagination={scorecardPagination()} />
          </div>
        )}
      </React.Fragment>
    );
  }

  function selectedScorecardNameEditInput() {
    return (
      <TextField
        label='Change Scorecard Name'
        size='small'
        onChange={handleScorecardNameEditChange}
        fullWidth
        multiline
        value={editScorecardsIdDict[selectedScorecardId].name}
      />
    );
  }

  function smallScreenPageListOverlayModal() {
    return (
      <React.Fragment>
        <Modal
          open={smallscreenSidebarOpen}
          onClose={() => {
            setSmallscreenSidebarOpen(false);
          }}
          style={{
            height: '100%',
            zIndex: 99,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '70vw',
              maxHeight: '80vh',
              overflowY: 'auto',
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 2,
            }}
          >
            <Box sx={{width: '100%', display: 'block'}}>{scorecardSideBar()}</Box>
          </Box>
        </Modal>
      </React.Fragment>
    );
  }

  function scorecardSideBar() {
    return (
      <Grid sx={sideNavWrapperStyle} container spacing={0}>
        {/* Reports Section */}
        <Grid item sm={12}>
          <List
            sx={[
              {
                '.MuiListItemIcon-root': {minWidth: '25px', paddingRight: '3px'},
                '.MuiListItemText-primary': {color: '#888888', fontWeight: 500},
                '.MuiListItemText-secondary': {color: '#888888', fontWeight: 700},
                '.Mui-selected .MuiListItemText-primary': {color: '#08C'},
                '.Mui-selected .MuiListItemIcon-root': {color: '#08C'},
                '.Mui-selected .MuiListItemText-secondary': {color: '#08C'},
              },
              {width: '100%'},
            ]}
            component='nav'
            disablePadding
          >
            <ListItemButton
              onClick={() => {
                handleOpenList('report');
              }}
              dense
              sx={{px: 1}}
            >
              <ListItemText sx={{'.MuiListItemText-primary': {fontSize: '1rem'}}} primary='Reports' />
              {reportListOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={reportListOpen} timeout='auto' unmountOnExit>
              <List disablePadding>
                {farmingReportsNav()}
                {machineryReportsNav()}
              </List>
            </Collapse>
          </List>
        </Grid>
        <Divider sx={{...dividerStyle, borderColor: '#888888'}} />
        {/* Scorecards Section */}
        <Grid item sm={12}>
          <List
            sx={[
              {
                '.MuiListItemIcon-root': {minWidth: '25px', paddingRight: '3px'},
                '.MuiListItemText-primary': {color: '#888888', fontWeight: 700},
                '.Mui-selected .MuiListItemText-primary': {color: '#08C'},
                '.Mui-selected .MuiListItemIcon-root': {color: '#08C'},
              },
              {width: '100%'},
            ]}
            component='nav'
            disablePadding
          >
            <ListItemButton
              onClick={() => {
                setReportListOpen(reportListOpen && scorecardListOpen);
                setScorecardListOpen(!scorecardListOpen);
              }}
              dense
              sx={{px: 1}}
            >
              <ListItemText sx={{'.MuiListItemText-primary': {fontSize: '1rem'}}} primary='Scorecards' />
              {scorecardListOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </List>

          <Divider sx={dividerStyle} />
          {loading ? (
            <Box sx={{width: '100%', textAlign: 'center'}}>
              <CircularProgress />
            </Box>
          ) : (
            <Collapse in={scorecardListOpen} timeout='auto' unmountOnExit>
              <FormControl variant='standard' fullWidth sx={{maxWidth: '100%', padding: '8px'}}>
                {!loading && !editMode && scorecardSelector()}
                {!loading && editMode && editScorecardsIdDict.hasOwnProperty(selectedScorecardId) && (
                  <React.Fragment>
                    <Box>{selectedScorecardNameEditInput()}</Box>
                    <Box>{datePickerRender()}</Box>
                  </React.Fragment>
                )}
              </FormControl>
            </Collapse>
          )}
        </Grid>
        {/* Scorecard Page Section */}
        {selectedScorecardId != null && scorecardListOpen && (
          <Grid item sm={12}>
            <Box
              sx={
                selectedScorecardPageIndex == null
                  ? sideNavSubheaderStyle
                  : {...sideNavSubheaderStyle, color: '#056905'}
              }
            >
              Content
            </Box>
            <Divider sx={dividerStyle} />
            {loading ? (
              <Box sx={{width: '100%', textAlign: 'center'}}>
                <CircularProgress />
              </Box>
            ) : (
              scorecardSideBarPageList()
            )}
          </Grid>
        )}
      </Grid>
    );
  }

  function getKPIbySubset(subset, props = {}) {
    if (subset == 'Efficiency') {
      return <KpiEfficiency {...props} />;
    }
    if (subset == 'Operational Efficiency') {
      return <KpiOperationalEfficiency {...props} />;
    }
    if (subset == 'Tasks Logged') {
      return <KpiTasksLogged {...props} />;
    }
    if (subset == 'Missing Tasks') {
      return <KpiMissingTasks {...props} />;
    }
    if (subset == 'Machine Usage') {
      return <KpiMachineUsage {...props} />;
    }
    if (subset == 'Idling') {
      return <KpiIdling {...props} />;
    }
    if (subset == 'Service Costs') {
      return <KpiServiceCosts {...props} />;
    }
    if (subset == 'Service Health') {
      return <KpiServiceCompletion {...props} />;
    }
    if (subset == 'Inspection Rates') {
      return <KpiInspectionRates {...props} />;
    }
  }

  function displayScoreCardKPI() {
    // Select ScoreCard Effect
    let graph = null;

    if (
      selectedScorecardPageIndex != null &&
      selectedScorecardId != null &&
      selectedScorecardId != '' &&
      scorecardsIdDict.hasOwnProperty(selectedScorecardId)
    ) {
      const selectedScoreCardPageObject = !editMode
        ? scorecardsIdDict[selectedScorecardId].pages[selectedScorecardPageIndex]
        : editScorecardsIdDict[selectedScorecardId].pages[selectedScorecardPageIndex];
      if (selectedScoreCardPageObject) {
        if (selectedScoreCardPageObject.selectedSubset != '') {
          const scoreCardKPISubset = selectedScoreCardPageObject.selectedSubset;
          // WIP add all KPI HERE
          graph = getKPIbySubset(scoreCardKPISubset, {
            graphOnly: true,
            pageFilters: selectedScoreCardPageObject?.pageFilters,
            pageStates: selectedScoreCardPageObject?.pageStates,
            key: selectedScoreCardPageObject?.pageId,
          });
        }
      }
    }
    return graph;
  }

  let pagesSideBarList = scorecardsIdDict[selectedScorecardId]?.pages;
  if (editMode) {
    pagesSideBarList = editScorecardsIdDict[selectedScorecardId]?.pages;
  }

  return (
    <React.Fragment>
      <Box sx={{flexDirection: 'column', display: 'flex', height: '100%', width: '100%'}}>
        <Grid container spacing={0} sx={{height: '100%'}}>
          <Grid
            item
            sm={2.5}
            sx={{height: '100%', width: '100%', overflowY: 'auto', overflowX: 'hidden'}}
            display={displaySettings.smallScreen ? 'none' : 'flex'}
          >
            {scorecardSideBar()}
          </Grid>
          <Grid item sm={displaySettings.smallScreen ? 12 : 9.5} sx={{height: '100%', width: '100%'}}>
            <Box sx={scorecardContentCardStyle}>
              <Box sx={{flexShrink: 0}}>
                <Grid
                  container
                  direction='row'
                  justifyContent='start'
                  alignItems='center'
                  sx={{
                    paddingX: '10px',
                  }}
                >
                  {selectedKPI == 'Scorecards' && scorecardControlButtons()}
                  {selectedKPI != 'Scorecards' && (
                    <React.Fragment>
                      <Grid
                        item
                        xs={10}
                        md={7}
                        sx={{fontSize: '1.7rem', color: '#505050', fontWeight: 600, fontSize: '1.8rem', paddingY: 1.5}}
                      >
                        <FontAwesomeIcon
                          style={{width: '27px', paddingRight: '3px'}}
                          icon={subsetToNameIconDict[selectedSubset].icon}
                        />
                        {subsetToNameIconDict[selectedSubset].name} <KpiTooltip selectedSubset={selectedSubset} />
                      </Grid>
                      <Grid item xs={1} md={3.5}>
                        <Box
                          className='hoverPls btn px-0'
                          style={{justifyContent: 'center', alignItems: 'center'}}
                          onClick={() => {
                            dispatch(updateAddToScorecardTrigger());
                          }}
                          display={{xs: 'none', md: 'flex'}}
                        >
                          <div>
                            Add Current View to Scorecard <FontAwesomeIcon icon='fa-solid fa-plus' />
                          </div>
                        </Box>
                        <Box
                          style={{justifyContent: 'center', alignItems: 'center'}}
                          display={{xs: 'flex', sm: 'flex', md: 'none'}}
                        >
                          <button
                            className='btn border-dark btn-light col-12 px-0'
                            onClick={() => {
                              dispatch(updateAddToScorecardTrigger());
                            }}
                          >
                            <FontAwesomeIcon icon='fa-solid fa-plus' />
                          </button>
                        </Box>
                      </Grid>
                      <Grid item xs={1} md={1.5}>
                        <ExportButton />
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
                <Grid
                  container
                  direction='row'
                  justifyContent='start'
                  sx={{
                    paddingX: '10px',
                  }}
                >
                  <Grid item xs={4} md={4} sx={{display: 'flex'}}>
                    <Menu />
                  </Grid>
                  <Grid item xs={7} md={6} lg={5} sx={{display: 'flex'}}>
                    <CompareBy />
                  </Grid>
                  {displaySettings.smallScreen && !loading && selectedKPI != 'Scorecards' && (
                    <Grid item xs={1} md={2}>
                      <button
                        className='btn border-dark btn-light w-100 cropview-menu-text'
                        onClick={() => {
                          setSmallscreenSidebarOpen(true);
                        }}
                      >
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25px'}}>
                          <FontAwesomeIcon
                            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '18px'}}
                            icon='fas fa-list'
                          />
                        </div>
                      </button>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box sx={{flexGrow: 1, overflow: 'auto'}}>
                {selectedKPI == 'Scorecards' ? scorecardContent() : getKPIbySubset(selectedSubset)}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {smallScreenPageListOverlayModal()}
    </React.Fragment>
  );
}

export {ScoreCardWrapper};
